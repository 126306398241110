import { PageWrapper } from 'components/PageWrapper';
import PropTypes from 'prop-types';
import { usePageTitle } from 'utils/hooks';
import { AnnouncementsTableView } from './components';

export default function Announcements({ globalTabName, locationSearch }) {
  usePageTitle('Announcements', globalTabName);

  return (
    <PageWrapper title="Announcements">
      <AnnouncementsTableView locationSearch={locationSearch} />
    </PageWrapper>
  );
}

Announcements.propTypes = {
  globalTabName: PropTypes.string,
  locationSearch: PropTypes.string,
};

Announcements.defaultProps = {
  globalTabName: '',
  locationSearch: '',
};
