import { Space, Tabs, Text } from '@mantine/core';
import { useState } from 'react';
import { JobOpenings } from './white-list-tabs';

const tabs = [{ value: 'job-openings', title: 'Job Openings' }];

export default function WhiteListProvider() {
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <>
      <Space h="md" />
      <Tabs
        onChange={setSelectedTab}
        value={selectedTab}
        orientation="vertical"
        keepMounted={false}
      >
        <Tabs.List mr={15}>
          {tabs.map((tab) => (
            <Tabs.Tab
              w="100%"
              bg={selectedTab === tab.value ? 'blue' : undefined}
              c={selectedTab === tab.value ? 'white' : undefined}
              value={tab.value}
              key={tab.value}
            >
              <Text p={5}>{tab.title}</Text>
            </Tabs.Tab>
          ))}
        </Tabs.List>
        <Tabs.Panel value="job-openings">
          <JobOpenings />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
