import { Button, Menu, Modal, Text, Textarea, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCode, IconSend, IconSettings } from '@tabler/icons-react';
import { CopyButton } from 'components/CopyButton';

export default function JobOpeningsOptions() {
  const [openedEmbedCode, { open: openEmbedCode, close: closeEmbedCode }] =
    useDisclosure(false);

  return (
    <>
      <EmbedCodeModal opened={openedEmbedCode} onClose={closeEmbedCode} />
      <Menu shadow="md" width={250}>
        <Menu.Target>
          <Button leftSection={<IconSettings />}>Options</Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label>Job Openings</Menu.Label>
          <Menu.Item
            leftSection={
              <IconSend style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={() => window.open('/job-openings', '_blank')}
          >
            View Careers Website
          </Menu.Item>
          <Menu.Item
            leftSection={
              <IconCode style={{ width: rem(14), height: rem(14) }} />
            }
            onClick={openEmbedCode}
          >
            Get Embed Code
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

function EmbedCodeModal({ ...rest }) {
  const code = `<iframe src="${window.location.protocol}//${window.location.host}/job-openings" width="100%" height="1100px" frameBorder="0" allow="clipboard-read; clipboard-write"></iframe>`;

  return (
    <Modal {...rest} centered title="To embed job openings on your website">
      <ol style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <li>
          <Text>Copy this embed code</Text>
          <Textarea
            rows={4}
            readOnly
            style={{ wordBreak: 'break-all' }}
            styles={{
              section: { alignItems: 'flex-start', marginTop: '10px' },
            }}
            rightSection={<CopyButton value={code} />}
            value={code}
          />
        </li>
        <li>
          Paste the embed code into your site&apos;s HTML where you want your
          job openings displayed. (You can also send it along to your awesome
          web developer and let the magic happen.)
        </li>
        <li>Done! Your job openings will now be posted on your website.</li>
      </ol>
    </Modal>
  );
}
