import { Modal } from '@mantine/core';
import PropTypes from 'prop-types';
import CreateEditAnnouncementForm from './CreateEditAnnouncementForm';

export default function CreateEditAnnouncementModal({
  opened,
  onClose,
  state,
  onEdit,
  onCreate,
  isLoading,
}) {
  const isEdit = state?.modalType === 'Edit';

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      title={`${state?.modalType ?? 'Create'} Announcement`}
      size="xl"
    >
      <CreateEditAnnouncementForm
        state={state}
        onSubmit={isEdit ? onEdit : onCreate}
        isLoading={!opened || isLoading}
      />
    </Modal>
  );
}

CreateEditAnnouncementModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  state: PropTypes.object,
};

CreateEditAnnouncementModal.defaultProps = {
  state: {},
};
