import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  GridCol,
  Input,
  Pagination,
  Paper,
  Stack,
  Title,
} from '@mantine/core';
import { IconClock, IconMapPin } from '@tabler/icons-react';
import { useLoadJobsOpening } from 'api/hooks';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { usePagination } from 'utils/hooks';

export default function JobOpenings() {
  const { page, setPage, handleEmptyLastPage } = usePagination({
    page: 1,
    canUseQuery: true,
  });

  const { jobOpenings, pagination, error, isLoading } = useLoadJobsOpening(
    {
      page,
    },
    { onSuccess: handleEmptyLastPage },
  );

  return (
    <Container h="100vh" py={100}>
      <Paper h="100%" pt={20} style={{ overflow: 'hidden' }}>
        {error && (
          <Input.Error fz={30} px={10} ta="center">
            This integration requires authorisation.
          </Input.Error>
        )}
        {error?.response?.status !== 403 && !isLoading && (
          <Stack
            justify="space-between"
            h="100%"
            px={20}
            style={{ borderRadius: 'var(--paper-radius)' }}
            gap={0}
          >
            <Title fw={500}>Current Openings</Title>
            <Divider my={20} />

            <Stack gap={10} mx={-20}>
              <Grid columns={8}>
                {_.map(jobOpenings, (job) => (
                  <React.Fragment key={job?.id}>
                    <GridCol span={4} c="blue" py={5}>
                      <Link
                        to={`${job?.id}`}
                        style={{ textDecoration: 'none', color: 'inherit' }}
                      >
                        {job?.title}
                      </Link>
                    </GridCol>
                    <GridCol span={2} py={5}>
                      <Flex align="center" gap={2}>
                        <IconMapPin />
                        {job?.location}
                      </Flex>
                    </GridCol>
                    <GridCol span={2} py={5}>
                      <Flex align="center" gap={2}>
                        <IconClock />
                        {job?.type}
                      </Flex>
                    </GridCol>
                    <GridCol span={8} py={5}>
                      <Divider />
                    </GridCol>
                  </React.Fragment>
                ))}
              </Grid>
            </Stack>

            {pagination?.total_pages > 1 && (
              <Flex justify="flex-end" mt={10}>
                <Pagination
                  total={pagination?.total_pages}
                  value={page}
                  onChange={setPage}
                />
              </Flex>
            )}

            <Box h={5} bg="var(--mantine-color-blue-3)" mx={-20} mt="auto" />
          </Stack>
        )}
      </Paper>
    </Container>
  );
}
