import {
  Button,
  Flex,
  InputLabel,
  Radio,
  Space,
  Stack,
  TextInput,
} from '@mantine/core';
import { IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { clearCache } from 'api/clearCache';
import { useDeleteFile } from 'api/hooks';
import { EmployeesFilter } from 'components/EmployeesFilter';
import { useEmployeeFilter } from 'components/EmployeesFilter/hooks';
import { FormEditorField } from 'components/FormEditorField';
import {
  employeeTypes,
  filterOnlySelectedValues,
  markEmployeeFiltersAsSelected,
} from 'components/PerformanceManagement/forms/utils';
import { UploadFile } from 'components/UploadFile';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { genericValidators } from 'utils';
import { AnnouncementStatuses } from 'utils/constants';

export default function CreateEditAnnouncementForm({
  state,
  isLoading,
  onSubmit,
}) {
  const queryClient = useQueryClient();
  const [preloadedFiles, setPreloadedFiles] = useState(state?.files ?? []);

  const form = useForm({
    initialValues: {
      title: state?.title ?? '',
      body: state?.body ?? '',
      employeeType: _.isEmpty(state?.filters)
        ? employeeTypes.all
        : employeeTypes.someEmployee,
      filters: markEmployeeFiltersAsSelected(state?.filters) ?? {},
      files: [],
    },
    validate: {
      title: (val) => genericValidators.notEmpty(val),
      body: (val) => genericValidators.notEmpty(val),
      filters: (val, { employeeType }) =>
        _.isEmpty(filterOnlySelectedValues(val)) &&
        employeeType === employeeTypes.someEmployee
          ? 'At least one must be selected'
          : null,
    },
  });

  const { employeeFilters, onChangeEmployeeFilter, isLookupsLoading } =
    useEmployeeFilter({
      initialValues: form.values.filters,
    });

  const handleFormSubmit = (payload) => {
    form.onSubmit(({ files, ...data }) =>
      onSubmit({
        ...data,
        ...payload,
        filters:
          data?.employeeType === employeeTypes.someEmployee
            ? filterOnlySelectedValues(data.filters)
            : {},
        files: _.map(files, 'file'),
      }),
    )();
  };

  const { deleteFile } = useDeleteFile({
    onSuccess: (params, payload) => {
      if (payload?.id) {
        setPreloadedFiles((files) =>
          files?.filter((item) => item?.id !== payload?.id),
        );
        clearCache.onChangeAnnounce(queryClient);
      }
    },
  });

  const onDeleteUploadedFile = (file, isPreloaded) => {
    if (!isPreloaded) {
      const { files } = form.values;

      form.setFieldValue(
        'files',
        files.filter((item) => item?.id !== file?.id),
      );
    } else {
      deleteFile({ id: file?.id });
    }
  };

  return (
    <form noValidate onSubmit={form.onSubmit(onSubmit)}>
      <Stack>
        <Flex gap={10} align="center">
          <TextInput
            placeholder="Announcement Title"
            label="Announcement Title"
            {...form.getInputProps('title')}
            required
            flex={1}
          />
        </Flex>
        <FormEditorField
          label="Announcement"
          required
          onChange={(val) => form.setFieldValue('body', val)}
          template={state?.body}
          errors={form.errors.body}
          placeholder="test"
        />

        <Stack gap={0}>
          <UploadFile
            selectedFiles={form.values.files}
            setSelectedFile={(file) =>
              form.setFieldValue('files', form.values.files.concat(file))
            }
            maxFileSize={2}
            preloadedFiles={preloadedFiles}
            accept={[...IMAGE_MIME_TYPE, PDF_MIME_TYPE].join(',')}
            multiple
            onDeleteFiles={onDeleteUploadedFile}
          />
        </Stack>

        <Radio.Group
          {...form.getInputProps('employeeType')}
          error={form.errors.employeeType}
        >
          <InputLabel required>Who should be included?</InputLabel>
          <Stack gap={10}>
            <Radio value={employeeTypes.all} label="All employees" />
            <Radio value={employeeTypes.someEmployee} label="Some employee" />
          </Stack>
        </Radio.Group>

        {form.values.employeeType === employeeTypes.someEmployee && (
          <>
            <Space h="md" />
            <EmployeesFilter
              error={form.errors.filters}
              onSelectFilter={(data) =>
                onChangeEmployeeFilter(data, (values) =>
                  form.setFieldValue('filters', values),
                )
              }
              employeeFilters={employeeFilters}
              isLookupsLoading={isLookupsLoading}
              selectedValues={form.values.filters}
            />
          </>
        )}
        <Flex ml="auto" gap={10}>
          <Button
            disabled={isLoading}
            onClick={() =>
              handleFormSubmit({ status: AnnouncementStatuses.Draft })
            }
            variant="outline"
          >
            Save Draft
          </Button>
          <Button
            disabled={isLoading}
            onClick={() =>
              handleFormSubmit({
                status: AnnouncementStatuses.PublishedAndEmail,
              })
            }
          >
            Publish & Email
          </Button>
          <Button
            disabled={isLoading}
            onClick={() =>
              handleFormSubmit({
                status: AnnouncementStatuses.Published,
              })
            }
          >
            Publish
          </Button>
        </Flex>
      </Stack>
    </form>
  );
}

CreateEditAnnouncementForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  state: PropTypes.object,
};

CreateEditAnnouncementForm.defaultProps = {
  state: {},
};
