import {
  Avatar,
  Flex,
  Group,
  Loader,
  Paper,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import { useHover } from '@mantine/hooks';
import moment from 'moment';
import PropTypes from 'prop-types';

import { IconChecks, IconPoint } from '@tabler/icons-react';
import { useMarkAsRead } from 'api/hooks';
import { useAuthContext } from 'auth';
import { useMemo } from 'react';
import { requestStatusesVal, tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';

const requestTypes = {
  compleatable: 'compleatable',
  approvable: 'approvable',
};

const urlByType = {
  [requestTypes.compleatable]: '/requests/completed-requests',
  [requestTypes.approvable]: '/requests/sent-requests',
};

export default function NotificationItem({
  notification,
  onMarkedAsRead,
  fullView,
  closeMenu,
}) {
  const theme = useMantineTheme();
  const navigate = useTabNavigate();
  const { hovered, ref } = useHover();
  const { user } = useAuthContext();

  const navigateTo = useMemo(
    () => ({
      Request: () => {
        const status = notification?.meta?.request_status;
        const url =
          status === requestStatusesVal.Pending || status === null
            ? '/requests/all-requests'
            : urlByType[notification?.meta?.request_type];

        if (url) {
          navigate(url, {
            state: { requestId: notification?.meta?.object_id },
            tabName: tabNames.requests,
          });
        }
      },
      Employee: () => {
        const tab = notification?.meta?.section;
        const employeeId = notification?.meta?.object_id;
        if (tab) {
          navigate(`/employees/${employeeId}/${tab}`, {
            tabName: tabNames.employeesProfile,
          });
        }
      },
      Announcement: () => {
        const announcementId = notification?.meta?.object_id;

        navigate(`/announcement/${announcementId}`, {
          tabName: tabNames.announcements,
        });
      },
    }),
    [navigate, notification],
  );

  const { markAsRead, isLoading } = useMarkAsRead({
    onSuccess: () => {
      onMarkedAsRead();
    },
  });

  const createdAt = notification.created_at
    ? moment(notification.created_at).format('DD/MM/YYYY, h:mm a')
    : '';
  const isUnread = notification?.status === 0;

  const onNotificationClick = (withNavigation = false) => {
    if (isUnread) {
      markAsRead({ userId: user?.id, notificationId: notification?.id });
    }
    if (withNavigation) {
      navigateTo?.[notification?.meta?.type]?.();
      closeMenu();
    }
  };

  const isNotRead = notification?.status === 0;

  return (
    <Paper
      onClick={() => onNotificationClick(true)}
      w="100%"
      shadow={hovered ? 'md' : 'sm'}
      withBorder
      ref={ref}
      style={{ cursor: 'pointer' }}
    >
      <Group p={10}>
        <Flex w="100%" gap={10}>
          <Avatar w={40} h={40} src={notification?.author?.avatar_filename} />
          <Flex style={{ flexGrow: 1 }} gap={5} w="100%" direction="column">
            <Text
              size="md"
              fw={isNotRead ? 700 : 400}
              c={isNotRead ? 'var(--dark-white)' : 'gray'}
            >
              {notification.title}
            </Text>
            {fullView && <Text size="md">{notification.body}</Text>}
            <Text size="xs" c="dimmed">
              {createdAt}
            </Text>
          </Flex>
          {!isLoading && isUnread && (
            <Flex align="center">
              {hovered && (
                <IconChecks
                  style={{ width: rem(25), height: rem(25) }}
                  color={theme.colors.blue[6]}
                  onClick={(e) => {
                    e.stopPropagation();
                    onNotificationClick();
                  }}
                />
              )}
              {!hovered && (
                <IconPoint
                  style={{ width: rem(30), height: rem(30) }}
                  color={theme.colors.blue[6]}
                />
              )}
            </Flex>
          )}
          {isLoading && (
            <Flex align="center">
              <Loader color="blue" size="sm" />
            </Flex>
          )}
        </Flex>
      </Group>
    </Paper>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object.isRequired,
  onMarkedAsRead: PropTypes.func,
  fullView: PropTypes.bool,
  closeMenu: PropTypes.func,
};

NotificationItem.defaultProps = {
  onMarkedAsRead: () => {},
  closeMenu: () => {},
  fullView: false,
};
