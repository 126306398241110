import { Flex, Pagination, Stack, Table } from '@mantine/core';
import { clearCache } from 'api/clearCache';
import {
  useCreateAnnounce,
  useDeleteAnnouncement,
  useLoadAnnouncements,
  useUpdateAnnouncement,
} from 'api/hooks';
import { AddButton } from 'components/Buttons';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { CreateEditAnnouncementModal } from 'components/CreateEditAnnouncement';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useQueryClient } from 'react-query';
import { tabNames } from 'utils/constants';
import {
  usePagination,
  useStateDisclosure,
  useUpdateTabQuery,
} from 'utils/hooks';
import TableRow from './TableRow';

const headerList = ['Title', 'Author', 'Publisher', 'Status', 'Date', ''];

export default function AnnouncementsTableView({ locationSearch }) {
  const queryClient = useQueryClient();
  const { setNotifications } = useNotifications();

  const { page, setPage, handleEmptyLastPage } = usePagination({
    page: Number(queryString.parse(locationSearch)?.page ?? 1),
  });

  useUpdateTabQuery({
    globalTabName: tabNames.announcements,
    queryObject: { page },
  });

  const { permissions, hasPermission } = usePermissions();

  const {
    announcements,
    pagination,
    isLoading: isAnnouncementsLoading,
  } = useLoadAnnouncements(
    {
      hasManageEmployee: hasPermission(permissions.canManageEmployee),
      page,
    },
    {
      onSuccess: handleEmptyLastPage,
    },
  );

  const [
    openedCreateEditAnnouncements,
    {
      open: openCreateEditAnnouncement,
      close: closeCreateEditAnnouncement,
      state: announcementState,
    },
  ] = useStateDisclosure(false);

  const { createAnnounce, isLoading: isCreatingAnnounce } = useCreateAnnounce({
    onSuccess: () => {
      closeCreateEditAnnouncement();
      clearCache.onChangeAnnounce(queryClient);
      setNotifications(['Announce Created Successfully']);
    },
  });

  const [
    openedDeleteConfirmation,
    {
      open: openConfirmationModal,
      close: closeConfirmationModal,
      state: deleteState,
    },
  ] = useStateDisclosure(false);

  const { deleteAnnouncement, isLoading: isDeletingAnnouncement } =
    useDeleteAnnouncement({
      onSuccess: () => {
        closeConfirmationModal();
        clearCache.onChangeAnnounce(queryClient);
        setNotifications(['Announce Deleted Successfully']);
      },
    });

  const { updateAnnouncement, isLoading: isUpdating } = useUpdateAnnouncement({
    onSuccess: () => {
      closeCreateEditAnnouncement();
      clearCache.onChangeAnnounce(queryClient);
      setNotifications(['Announce Updated Successfully']);
    },
  });

  return (
    <Stack gap={0}>
      <CreateEditAnnouncementModal
        opened={openedCreateEditAnnouncements}
        onClose={closeCreateEditAnnouncement}
        state={announcementState}
        onCreate={(data) => createAnnounce({ ...data })}
        onEdit={(payload) =>
          updateAnnouncement({
            announcementId: announcementState?.id,
            ...payload,
          })
        }
        isLoading={isCreatingAnnounce || isUpdating}
      />
      <ConfirmDeleteModal
        opened={openedDeleteConfirmation}
        onClose={closeConfirmationModal}
        onDelete={() => deleteAnnouncement(deleteState?.id)}
        deleteItemName={deleteState?.title}
        isLoading={isDeletingAnnouncement}
      />
      <PermissionGuard permission={permissions.canManageEmployee}>
        <Flex w="100%" justify="end">
          <AddButton onClick={() => openCreateEditAnnouncement()}>
            New Announcement
          </AddButton>
        </Flex>
      </PermissionGuard>
      <EmptyDataMessage
        isVisible={_.isEmpty(announcements) && !isAnnouncementsLoading}
      />
      <Loader isLoading={isAnnouncementsLoading} />
      {!_.isEmpty(announcements) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              {_.map(headerList, (item) => (
                <Table.Th key={item}>{item}</Table.Th>
              ))}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(announcements, (announcement) => (
              <TableRow
                announcement={announcement}
                onDelete={openConfirmationModal}
                onEdit={openCreateEditAnnouncement}
                key={announcement?.id}
              />
            ))}
          </Table.Tbody>
        </Table>
      )}
      {pagination?.total_pages > 1 && (
        <Flex justify="flex-end" my={10}>
          <Pagination
            total={pagination?.total_pages}
            value={page}
            onChange={setPage}
          />
        </Flex>
      )}
    </Stack>
  );
}

AnnouncementsTableView.propTypes = {
  locationSearch: PropTypes.string,
};

AnnouncementsTableView.defaultProps = {
  locationSearch: '',
};
