import { AppShell, Burger, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useAuthContext } from 'auth';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { GlobalTabsContextProvider } from 'globalTabs';
import GlobalTabItem from 'globalTabs/GlobalTabItem';
import GlobalTabsLayout from 'globalTabs/GlobalTabsLayout';
import { HeadCount } from 'modules/reports/HeadCount';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { tabNames } from 'utils/constants';
import {
  Administration,
  AnnouncementPreview,
  Announcements,
  Calendar,
  CandidateProfile,
  CreateCandidate,
  CreateEmployee,
  CreateJob,
  CreateTalentGroup,
  EditCandidate,
  EditEmployee,
  EditJob,
  EditTalentGroup,
  EmployeeProfile,
  EmployeesProvider,
  GlobalSearch,
  Hiring,
  Home,
  HumanResources,
  JobProfile,
  NotFound,
  Notifications,
  OrganizationFiles,
  PerformanceCycle,
  PolicyCreate,
  PolicyEdit,
  Recruitment,
  Reports,
  Requests,
  Settings,
  SurveyEdit,
  TalentGroupProfile,
  UserProfile,
} from '..';
import { Navbar } from '../../components/Navbar';
import { Sidebar } from '../../components/Sidebar';
import classes from './Dashboard.module.css';

function RouteFallback() {
  return <Navigate replace to="/" />;
}
function Dashboard() {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const { permissions } = usePermissions();

  const { isLoggedIn } = useAuthContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return (
    <GlobalTabsContextProvider>
      <AppShell
        layout="alt"
        header={{ height: 60 }}
        navbar={{
          width: !desktopOpened ? 300 : 70,
          breakpoint: 'sm',
          collapsed: { mobile: !mobileOpened },
        }}
        padding="xs"
      >
        <AppShell.Header>
          <Group h="100%" px="md" className={classes.spaceBetween}>
            <Burger
              opened={mobileOpened}
              onClick={toggleMobile}
              hiddenFrom="sm"
              size="sm"
            />
            <Burger
              opened={!desktopOpened}
              onClick={toggleDesktop}
              visibleFrom="sm"
              size="sm"
            />
            <Navbar />
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md" h="100%">
          <Sidebar desktopOpened={desktopOpened} />
        </AppShell.Navbar>
        <AppShell.Main
          className={classes.dashboardMainContainer}
          bg="var(--white)"
        >
          <GlobalTabsLayout />
          <Routes>
            <Route
              path="/hr"
              element={
                <GlobalTabItem tab={tabNames.hr}>
                  <HumanResources />
                </GlobalTabItem>
              }
            />
            <Route
              path="/recruitment"
              element={
                <GlobalTabItem tab={tabNames.recruitment}>
                  <Recruitment />
                </GlobalTabItem>
              }
            />
            <Route
              path="/user/profile/:tab?"
              element={
                <GlobalTabItem tab={tabNames.userProfile}>
                  <UserProfile />
                </GlobalTabItem>
              }
            />
            <Route
              path="/my-profile/:tab?"
              element={
                <PermissionGuard
                  permission={permissions.canViewMyProfile}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.myProfile}>
                    <EmployeeProfile isMyProfile />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/my-profile/:tab?/edit"
              element={
                <PermissionGuard
                  permission={permissions.canManageEmployee}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.myProfile}>
                    <EditEmployee isMyProfile />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />

            <Route
              path="/employees"
              element={
                <PermissionGuard
                  permission={permissions.canViewEmployees}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.employees} withParams>
                    <EmployeesProvider />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/employees/create"
              element={
                <PermissionGuard
                  permission={permissions.canManageEmployee}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.createEmployees}>
                    <CreateEmployee />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/employees/:userId/:tab?"
              element={
                <PermissionGuard
                  permission={permissions.canViewEmployees}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.employeesProfile} withParams>
                    <EmployeeProfile />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/employees/:userId/:tab?/edit"
              element={
                <PermissionGuard
                  permission={permissions.canManageEmployee}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.editEmployees}>
                    <EditEmployee />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />

            <Route
              path="/hiring/:tab?"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.hiring}>
                    <Hiring />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/job/:jobId"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.hiringJob} withParams>
                    <JobProfile />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/job/create"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.createHiringJob} withParams>
                    <CreateJob />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/job/:jobId/edit"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.editHiringJob} withParams>
                    <EditJob />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/job/:jobId/candidate/create"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.hiringJobCandidate} withParams>
                    <CreateCandidate />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/job/:jobId/candidate/:candidateId"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem
                    tab={tabNames.hiringJobCandidate}
                    withParams
                    usedParams={['jobId', 'candidateId']}
                  >
                    <CandidateProfile />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/job/:jobId/candidate/:candidateId/edit"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.hiringJobCandidate} withParams>
                    <EditCandidate />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/talent-group/:groupId"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.hiringTalentGroup} withParams>
                    <TalentGroupProfile />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/talent-group/create"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.createTalentGroup} withParams>
                    <CreateTalentGroup />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/hiring/talent-group/:groupId/edit"
              element={
                <PermissionGuard
                  permission={permissions.canAccessHiring}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.editTalentGroup} withParams>
                    <EditTalentGroup />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />

            <Route
              path="/requests/:tabValue?/:requestType?/:requestId?"
              element={
                <PermissionGuard
                  permission={permissions.canManageRequests}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.requests} withParams>
                    <Requests />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />

            <Route
              path="/reports"
              element={
                <PermissionGuard
                  permission={permissions.canAccessReports}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.reports} withParams>
                    <Reports />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/reports/headcount"
              element={
                <PermissionGuard
                  permission={permissions.canAccessReports}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.reports} withParams>
                    <HeadCount />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/settings/:tabValue?"
              element={
                <PermissionGuard
                  permission={permissions.canAccessSettings}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.settings} withParams>
                    <Settings />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/settings/performance-management/:cycleId/edit"
              element={
                <PermissionGuard
                  permission={permissions.canAccessSettings}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem
                    tab={tabNames.settingsPerformanceManagement}
                    withParams
                  >
                    <PerformanceCycle />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/settings/surveys/:surveyId/edit"
              element={
                <PermissionGuard
                  permission={permissions.canAccessSettings}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.settingsSurveys} withParams>
                    <SurveyEdit />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />

            <Route
              path="/settings/time-off/create/:policyType"
              element={
                <PermissionGuard
                  permission={permissions.canAccessSettings}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.settings} withParams>
                    <PolicyCreate />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />

            <Route
              path="/settings/time-off/:policyId/edit"
              element={
                <PermissionGuard
                  permission={permissions.canAccessSettings}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.settings} withParams>
                    <PolicyEdit />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />

            <Route
              path="/global-search/:search?"
              element={
                <GlobalTabItem tab={tabNames.globalSearch} withParams>
                  <GlobalSearch />
                </GlobalTabItem>
              }
            />

            <Route
              path="/administration"
              element={
                <PermissionGuard
                  permission={permissions.canAccessAdmin}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.administration}>
                    <Administration />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/notifications"
              element={
                <GlobalTabItem tab={tabNames.notifications}>
                  <Notifications />
                </GlobalTabItem>
              }
            />
            <Route
              path="/calendar"
              element={
                <GlobalTabItem tab={tabNames.calendar}>
                  <Calendar />
                </GlobalTabItem>
              }
            />
            <Route
              path="/files"
              element={
                <PermissionGuard
                  permission={permissions.canManageEmployee}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.files}>
                    <OrganizationFiles />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/announcements"
              element={
                <PermissionGuard
                  permission={permissions.canViewEmployees}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.announcements}>
                    <Announcements />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              path="/announcement/:id"
              element={
                <PermissionGuard
                  permission={permissions.canViewEmployees}
                  fallback={<RouteFallback />}
                >
                  <GlobalTabItem tab={tabNames.announcements}>
                    <AnnouncementPreview />
                  </GlobalTabItem>
                </PermissionGuard>
              }
            />
            <Route
              exact
              path="/"
              element={
                <GlobalTabItem tab={tabNames.dashboard}>
                  <Home />
                </GlobalTabItem>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AppShell.Main>
      </AppShell>
    </GlobalTabsContextProvider>
  );
}

export default Dashboard;
