import { Box, Card, Flex, Loader, Stack } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import classes from '../styles.module.css';

export default function WidgetWrapper({
  title,
  Icon,
  isLoading,
  children,
  deletable,
  onDelete,
  isCurrentItemDeleting,
  wrapperProps,
  rightSection,
}) {
  return (
    <Card
      withBorder
      mih={300}
      h={300}
      radius="md"
      p={0}
      className={classes.card}
      styles={{
        root: {
          padding: '0px !important',
          border: '1px solid var(--mantine-color-gray-3)',
          borderBottom: '1px solid var(--mantine-color-dark-1)',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        },
      }}
      w="100%"
      {...wrapperProps}
    >
      <Box
        bg="var(--mantine-color-blue-6)"
        fz="xl"
        p="xl"
        className={classes.label}
        c="white"
      >
        <Flex align="center" justify="space-between">
          <Flex align="center" gap={10}>
            {Icon}
            {title}
          </Flex>

          <Flex align="center">
            {rightSection}
            {isCurrentItemDeleting && (
              <Loader color="var(--mantine-color-white)" size={20} />
            )}
            {!isCurrentItemDeleting && deletable && (
              <IconTrash cursor="pointer" size={20} onClick={onDelete} />
            )}
          </Flex>
        </Flex>
      </Box>
      <Stack
        gap={0}
        style={{ overflow: 'auto', overscrollBehavior: 'none' }}
        h="100%"
        flex={1}
        pb={20}
        px="md"
        mt={10}
        w="100%"
      >
        {isLoading && <Loader m="auto" />}
        {!isLoading && children}
      </Stack>
    </Card>
  );
}

WidgetWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.object,
  children: PropTypes.node.isRequired,
  deletable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isCurrentItemDeleting: PropTypes.bool,
  onDelete: PropTypes.func,
  wrapperProps: PropTypes.object,
  rightSection: PropTypes.object,
};

WidgetWrapper.defaultProps = {
  isLoading: false,
  deletable: false,
  onDelete: () => {},
  Icon: null,
  isCurrentItemDeleting: false,
  wrapperProps: {},
  rightSection: null,
};
