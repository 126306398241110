import { Flex } from '@mantine/core';
import { FilePreview } from 'components/FilePreview';
import { PDFPreviewModal } from 'components/PDFPreviewModal';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useStateDisclosure } from 'utils/hooks';
import FileItem from './FileItem';

const FileList = memo(({ files, onDeleteFiles, isPreloaded }) => {
  const [openedPreview, { open: openPreview, state: previewState, saveClose }] =
    useStateDisclosure(false);

  const [
    openedPdfPreview,
    {
      open: openPdfPreview,
      saveClose: closePdfPreview,
      state: pdfPreviewState,
    },
  ] = useStateDisclosure(false);

  const onClickPreview = (type, file) => {
    if (type === 'application/pdf') {
      if (isPreloaded) openPdfPreview(file);
    } else {
      openPreview(file);
    }
  };

  return (
    <Flex gap={10} mt={10} wrap="wrap">
      <FilePreview
        opened={openedPreview}
        onClose={saveClose}
        path={previewState?.file_path ?? previewState?.objectUrl}
      />
      <PDFPreviewModal
        opened={openedPdfPreview}
        onClose={closePdfPreview}
        fileId={pdfPreviewState?.id}
      />
      {files?.map((file) => (
        <FileItem
          key={file?.id}
          file={file}
          type={file?.file?.type ?? file?.metadata?.mime_type}
          onClickPreview={onClickPreview}
          onDeleteFiles={onDeleteFiles}
          path={file?.file_path ?? file?.objectUrl}
        />
      ))}
    </Flex>
  );
});

export default FileList;

FileList.propTypes = {
  files: PropTypes.array,
  onDeleteFiles: PropTypes.func,
  isPreloaded: PropTypes.bool,
};
FileList.defaultProps = {
  files: [],
  onDeleteFiles: undefined,
  isPreloaded: false,
};
