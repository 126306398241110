import { Stack, Tabs } from '@mantine/core';
import { useAuthContext } from 'auth';
import { usePermissions } from 'components/PermissionGuard/hooks';
import { WidgetWrapper } from 'components/WidgetSettings/widgets';
import AllRequests from 'modules/requests/components/AllRequests';
import { useNavigate } from 'react-router-dom';
import { requestsCategories } from 'utils/constants';
import { AnnouncementTab } from '../components';

export default function WhatsHappening() {
  const { user } = useAuthContext();

  const { permissions, hasPermission } = usePermissions();

  const navigate = useNavigate();

  const navigateTo = (id, type) => {
    navigate(`/requests/all-requests/${requestsCategories[type]}/${id}`);
  };

  return (
    <WidgetWrapper title="What is happening" wrapperProps={{ h: 366 }}>
      <Tabs defaultValue="requests" w="100%" keepMounted={false}>
        <Tabs.List>
          <Tabs.Tab value="requests">Requests</Tabs.Tab>
          <Tabs.Tab value="announcements">Announcements</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="requests">
          <Stack gap={0} h="100%">
            <AllRequests
              onPreview={(data) => navigateTo(data?.id, data?.category)}
              payload={{
                Performance: {
                  assignee_id: user?.id,
                  status: [0],
                },
                TimeoffRecords: {
                  assignee_id: hasPermission(permissions.canManageEmployee)
                    ? ''
                    : user?.id,
                },
                Signature: {
                  assignee_id: user?.id,
                },
                status: [0],
              }}
              limit={5}
              displayStatus={false}
            />
          </Stack>
        </Tabs.Panel>
        <Tabs.Panel value="announcements">
          <AnnouncementTab />
        </Tabs.Panel>
      </Tabs>
    </WidgetWrapper>
  );
}
