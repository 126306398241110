import { Modal, Stack } from '@mantine/core';
import { Loader } from 'components/Loader';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function FilePreview({ opened, onClose, path }) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal opened={opened} onClose={onClose} centered size={1000}>
      <Stack mih={350} align="center" justify="center">
        <Loader isLoading={isLoading} />
        <img
          src={path}
          width="100%"
          height="100%"
          style={{ objectFit: 'contain' }}
          onLoadCapture={() => setIsLoading(true)}
          onLoad={() => setIsLoading(false)}
          alt="content"
          loading="lazy"
        />
      </Stack>
    </Modal>
  );
}

FilePreview.propTypes = {
  opened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

FilePreview.defaultProps = {
  path: undefined,
};
