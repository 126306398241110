import { CopyButton as CpyBtn } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import PropTypes from 'prop-types';

export default function CopyButton({ value }) {
  return (
    <CpyBtn value={value}>
      {({ copied, copy }) =>
        copied ? (
          <IconCheck cursor="pointer" onClick={copy} color="green" />
        ) : (
          <IconCopy cursor="pointer" onClick={copy} />
        )
      }
    </CpyBtn>
  );
}

CopyButton.propTypes = {
  value: PropTypes.string,
};

CopyButton.defaultProps = {
  value: '',
};
