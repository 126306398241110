import { Stack } from '@mantine/core';
import { IconFileTypePdf, IconFileUnknown, IconX } from '@tabler/icons-react';
import { Loader } from 'components/Loader';
import PropTypes from 'prop-types';
import { useState } from 'react';

export default function FileItem({
  file,
  type,
  onClickPreview,
  path,
  onDeleteFiles,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorOnLoad, setIsErrorOnLoad] = useState(false);

  const isPdf = type === 'application/pdf';
  return (
    <Stack
      gap={0}
      w={100}
      h={100}
      pos="relative"
      onClick={() => onClickPreview(type, file)}
      justify="center"
      style={{
        border: isErrorOnLoad
          ? '1px solid var(--mantine-color-red-3)'
          : '1px solid var(--mantine-color-gray-2)',
        borderRadius: '10px',
      }}
    >
      <Loader isLoading={isLoading} />
      {onDeleteFiles && (
        <IconX
          style={{ position: 'absolute', right: 0, top: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            onDeleteFiles?.(file);
          }}
          cursor="pointer"
        />
      )}

      {isPdf && (
        <IconFileTypePdf
          stroke={1}
          width="100%"
          height="100%"
          cursor="pointer"
        />
      )}

      {!isPdf && !isLoading && !isErrorOnLoad && (
        <img
          src={path}
          width="100%"
          height="100%"
          style={{
            borderRadius: '10px',
            cursor: 'pointer',
            objectFit: 'cover',
          }}
          onError={() => setIsErrorOnLoad(true)}
          onLoadCapture={() => setIsLoading(true)}
          onLoad={() => setIsLoading(false)}
          loading="lazy"
          alt="content"
        />
      )}

      {!isPdf && !isLoading && isErrorOnLoad && (
        <IconFileUnknown width="100%" height="100%" stroke={1} />
      )}
    </Stack>
  );
}

FileItem.propTypes = {
  file: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onClickPreview: PropTypes.func.isRequired,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onDeleteFiles: PropTypes.func,
};

FileItem.defaultProps = {
  path: '',
  onDeleteFiles: undefined,
};
