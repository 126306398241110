import {
  Anchor,
  Button,
  Checkbox,
  Container,
  Group,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { upperFirst, useToggle } from '@mantine/hooks';
import { useCurrentUserMutation, useLogin, useRegister } from 'api/hooks';
import { useAuthContext } from 'auth';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSetPageTitle } from 'utils/hooks';
import classes from './Authentication.module.css';
import { OAuth2List } from './components';

export default function Authentication() {
  const { setTokenData, setUser, prevUrl } = useAuthContext();
  const navigate = useNavigate();
  const [genericError, setGenericError] = useState();
  const { setNotifications } = useNotifications();
  const { getApiUser } = useCurrentUserMutation({
    onSuccess: (response) => {
      setUser(response.data);
      setNotifications(['You are successfully logged in']);
    },
  });

  const [type, toggle] = useToggle(['login', 'register']);

  const loginValidations = {
    email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
    password: (val) =>
      val.length <= 6 || val.length < 1
        ? 'Password should include at least 6 characters'
        : null,
  };
  const registerValidations = {
    ...loginValidations,
    firstName: (val) =>
      val.length > 50
        ? 'First Name should not be empty and no longer than 50 characters'
        : null,
    lastName: (val) =>
      val.length > 50
        ? 'Last Name should not be longer than 50 characters'
        : null,
    confirmPassword: (val) =>
      val.length <= 6 || val.length < 1
        ? 'Password should include at least 6 characters'
        : null,
  };

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      isRememberMe: true,
    },
    validate: type === 'register' ? registerValidations : loginValidations,
  });

  const { login, isLoading } = useLogin({
    onSuccess: ({ refresh_token: refreshToken, ...rest }) => {
      const tokenData = { ...rest };
      if (form.values.isRememberMe) tokenData.refresh_token = refreshToken;

      setTokenData(tokenData);
      navigate(prevUrl ?? '/', { replace: true });
      getApiUser();
    },
    onError: ({ response }) => {
      if (response.data?.message) {
        setGenericError(response.data?.message);
      }
    },
  });

  const { register, isLoading: isRegisterLoading } = useRegister({
    onSuccess: (data) => {
      setTokenData(data);
      if (data.user) {
        setUser(data.user);
        login(form.values);
      }
    },
  });

  const submitForm = (values) => {
    setGenericError();
    if (type === 'register') {
      register({
        ...values,
        first_name: values.firstName,
        last_name: values.lastName,
        password_confirmation: values.confirmPassword,
        company_id: 1,
      });
    }
    if (type === 'login') {
      login(values);
    }
  };

  useSetPageTitle('Login');

  return (
    <Container size={620} py={50}>
      <Title ta="center" className={classes.title}>
        Welcome back!
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        {type === 'register'
          ? 'Already have an account?'
          : 'Do not have an account yet?'}{' '}
        <Anchor
          size="sm"
          component="button"
          onClick={() => {
            form.reset();
            toggle();
          }}
        >
          {type === 'register' ? 'Log In' : 'Create account'}
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        {genericError && (
          <Paper mb={30} bg="red.2" radius="md" p="sm" align="center">
            <Text c="red.8" size="sm">
              {genericError}
            </Text>
          </Paper>
        )}
        <form onSubmit={form.onSubmit(submitForm)} noValidate>
          <TextInput
            label="Email"
            placeholder="email@mail.com"
            onChange={(event) =>
              form.setFieldValue('email', event.currentTarget.value)
            }
            value={form.values.email}
            error={form.errors.email}
            required
            data-testid="email-field"
          />
          {type === 'register' && (
            <>
              <TextInput
                label="First Name"
                placeholder="First Name"
                value={form.values.firstName}
                onChange={(event) =>
                  form.setFieldValue('firstName', event.currentTarget.value)
                }
                error={form.errors.firstName && 'Invalid First Name'}
                required
              />
              <TextInput
                label="Last Name"
                placeholder="Last Name"
                value={form.values.lastName}
                onChange={(event) =>
                  form.setFieldValue('lastName', event.currentTarget.value)
                }
                error={form.errors.lastName && 'Invalid Last Name'}
                required
              />
            </>
          )}
          <PasswordInput
            label="Password"
            placeholder="Your password"
            mt="md"
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue('password', event.currentTarget.value)
            }
            error={
              form.errors.password &&
              'Password should include at least 6 characters'
            }
            required
            data-testid="password-field"
          />
          {type === 'register' && (
            <PasswordInput
              label="Confirm Password"
              placeholder="Confirm password"
              mt="md"
              value={form.values.confirmPassword}
              onChange={(event) =>
                form.setFieldValue('confirmPassword', event.currentTarget.value)
              }
              error={form.errors.confirmPassword}
              required
            />
          )}

          {type === 'login' && (
            <Group justify="space-between" mt="lg">
              <Checkbox
                data-testid="remember-me-checkbox"
                checked={form.values.isRememberMe}
                {...form.getInputProps('isRememberMe')}
                label="Remember me"
              />
              <Link
                style={{
                  textDecoration: 'none',
                  color: 'var(--mantine-color-blue-6)',
                }}
                to="/forgot"
                size="sm"
              >
                Forgot password?
              </Link>
            </Group>
          )}
          <Stack gap={10}>
            <Button
              type="submit"
              fullWidth
              mt="xl"
              loading={isLoading || isRegisterLoading}
              data-testid={`${upperFirst(type)}-button`}
            >
              {upperFirst(type)}
            </Button>

            <OAuth2List />
          </Stack>
        </form>
      </Paper>
    </Container>
  );
}
