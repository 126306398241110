import { Flex, Table } from '@mantine/core';
import { clearCache } from 'api/clearCache';
import {
  useCreateJobOpeningWhiteListItem,
  useDeleteJobOpeningWhitelistItem,
  useLoadJobOpeningsWhiteList,
  useUpdateJobOpeningWhiteListItem,
} from 'api/hooks';
import { AddButton } from 'components/Buttons';
import { ConfirmDeleteModal } from 'components/ConfirmDeleteModal';
import { ContextMenu } from 'components/ContextMenu';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { OneFieldModal } from 'components/OneFieldModal';
import _ from 'lodash';
import { useNotifications } from 'notifications/hooks/useNotifications';
import { useQueryClient } from 'react-query';
import { formatDate } from 'utils';
import { useContextMenu, useStateDisclosure } from 'utils/hooks';

export default function JobOpenings() {
  const { setNotifications } = useNotifications();
  const queryClient = useQueryClient();

  const [
    openedCreateEditDomain,
    {
      open: openCreateEditDomain,
      close: closeCreateEditDomain,
      state: createEditDomainState,
    },
  ] = useStateDisclosure(false);

  const [
    openedConfirmationModal,
    {
      open: openConfirmationModal,
      saveClose: closeConfirmationModal,
      state: confirmationModalState,
    },
  ] = useStateDisclosure(false);

  const { whiteList, isLoading: isWhitelIstLoading } =
    useLoadJobOpeningsWhiteList();

  const { deleteJobOpeningWhitelistItem, isLoading: isDeleting } =
    useDeleteJobOpeningWhitelistItem({
      onSuccess: () => {
        setNotifications(['Domain/IP Deleted Successfully']);
        closeConfirmationModal();
        clearCache.onChangeJobOpeningWhiteList(queryClient);
      },
    });

  const { createJobOpeningWhiteListItem, isLoading: isCreating } =
    useCreateJobOpeningWhiteListItem({
      onSuccess: () => {
        setNotifications(['Domain/IP Added Successfully']);
        closeCreateEditDomain();
        clearCache.onChangeJobOpeningWhiteList(queryClient);
      },
    });

  const { updateJobOpeningListItem, isLoading: isUpdating } =
    useUpdateJobOpeningWhiteListItem({
      onSuccess: () => {
        setNotifications(['Domain/IP Updated Successfully']);
        closeCreateEditDomain();
        clearCache.onChangeJobOpeningWhiteList(queryClient);
      },
    });

  const { getContext } = useContextMenu();

  return (
    <>
      <Flex justify="flex-end">
        <AddButton onClick={openCreateEditDomain}>Add to whitelist</AddButton>
      </Flex>

      <OneFieldModal
        opened={openedCreateEditDomain}
        onClose={closeCreateEditDomain}
        state={createEditDomainState}
        onEdit={(data) =>
          updateJobOpeningListItem({
            recordId: createEditDomainState?.id,
            ...data,
          })
        }
        onCreate={createJobOpeningWhiteListItem}
        title="Add to white list"
        formSettings={{
          label: 'Domain or Ip',
          field: 'domain_or_ip',
        }}
        isLoading={isCreating || isUpdating}
      />

      <ConfirmDeleteModal
        opened={openedConfirmationModal}
        onClose={closeConfirmationModal}
        customText={`Are you sure you want to remove ${confirmationModalState?.domain_or_ip} from the whitelist?`}
        onDelete={() =>
          deleteJobOpeningWhitelistItem(confirmationModalState?.id)
        }
        isLoading={isDeleting}
      />

      <EmptyDataMessage
        isVisible={_.isEmpty(whiteList) && !isWhitelIstLoading}
      />
      <Loader isLoading={isWhitelIstLoading} />

      {!_.isEmpty(whiteList) && (
        <Table>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Domain Or IP</Table.Th>
              <Table.Th>Created At</Table.Th>
              <Table.Th />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {_.map(whiteList, (record) => (
              <Table.Tr key={record?.id}>
                <Table.Td>{record?.domain_or_ip}</Table.Td>
                <Table.Td>{formatDate(record?.created_at)}</Table.Td>
                <Table.Td ta="right">
                  <ContextMenu
                    menuItems={getContext({
                      context: record,
                      onDelete: openConfirmationModal,
                      onEdit: openCreateEditDomain,
                    })}
                  />
                </Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table>
      )}
    </>
  );
}
