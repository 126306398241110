import { Button, Divider, Flex, Stack, Text } from '@mantine/core';
import { IconNews } from '@tabler/icons-react';
import { useLoadAnnouncements } from 'api/hooks';
import { EmptyDataMessage } from 'components/EmptyDataMessage';
import { Loader } from 'components/Loader';
import { usePermissions } from 'components/PermissionGuard/hooks';
import _ from 'lodash';
import { formatDate, getUserFullName } from 'utils';
import { AnnouncementStatuses, tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import styles from '../../Home.module.css';

export default function AnnouncementsTab() {
  const { permissions, hasPermission } = usePermissions();

  const { announcements, pagination, isLoading } = useLoadAnnouncements({
    hasManageEmployee: hasPermission(permissions.canManageEmployee),
    page: 1,
    limit: 10,
    filter: {
      byStatus: AnnouncementStatuses.Published,
    },
  });

  const navigate = useTabNavigate();

  return (
    <Stack gap={10} justify="center" my="auto" flex={1}>
      <Loader isLoading={isLoading} mt={10} />
      <EmptyDataMessage isVisible={!isLoading && _.isEmpty(announcements)} />
      {_.map(announcements, (announcement) => (
        <Flex
          w="100%"
          className={styles.recordHover}
          gap={0}
          key={announcement?.id}
          onClick={() =>
            navigate(`/announcement/${announcement.id}`, {
              tabName: tabNames.announcements,
            })
          }
        >
          <Flex gap={10} py={5} px={10} align="center" w="100%">
            <IconNews size={30} />
            <Stack gap={0}>
              <Flex gap={5}>
                <Text fw={500}>{getUserFullName(announcement?.publisher)}</Text>
                <Text>is announcing</Text>
                <Text fw={500}>{announcement?.title}</Text>
              </Flex>
              <Text fz={15}>{formatDate(announcement?.published_at)}</Text>
            </Stack>
          </Flex>
          <Divider />
        </Flex>
      ))}
      {pagination?.total_pages > 1 && (
        <Button
          variant="transparent"
          c="gray"
          onClick={() =>
            navigate('/announcements', {
              tabName: tabNames.announcements,
            })
          }
        >
          View all Announcements
        </Button>
      )}
    </Stack>
  );
}
