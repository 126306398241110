import _ from 'lodash';
import { lookupsModels } from 'utils/constants';
import { queryKeys } from './keys';

export const clearCache = {
  onCandidateChange: (queryClient, { candidateId, jobId }) => {
    queryClient.removeQueries({
      queryKey: queryKeys.candidate(candidateId),
    });
    queryClient.removeQueries([queryKeys.candidates()[0]]);
    queryClient.invalidateQueries([queryKeys.job(jobId)[0]]);
    queryClient.invalidateQueries([queryKeys.jobs()[0]]);
    queryClient.invalidateQueries([queryKeys.talentGroup()[0]]);
  },
  onTalentGroupChange: (queryClient, { groupId }) => {
    queryClient.invalidateQueries(queryKeys.talentGroups());
    queryClient.invalidateQueries(queryKeys.talentGroup(groupId));
  },

  onCategoryChange: (queryClient) => {
    queryClient.invalidateQueries([queryKeys.trainingsCategories()[0]]);
    queryClient.invalidateQueries(queryKeys.trainings());
  },
  onLookupChange: (queryClient, model) => {
    queryClient.invalidateQueries({
      // clear only if model used in lookups
      predicate: ({ queryKey }) =>
        queryKey?.length > 1 &&
        queryKey[0] === queryKeys.lookups({ models: [] })[0] &&
        queryKey[1].includes(model),
    });
  },
  onJobTitleChange(queryClient) {
    queryClient.invalidateQueries(queryKeys.jobTitles());
    this.onLookupChange(queryClient, lookupsModels.jobTitle);
  },
  onDepartmentChange(queryClient) {
    queryClient.invalidateQueries(queryKeys.departments());
    this.onLookupChange(queryClient, lookupsModels.department);
  },
  onDivisionChange(queryClient) {
    queryClient.invalidateQueries(queryKeys.divisions());
    this.onLookupChange(queryClient, lookupsModels.division);
  },
  onLocationChange(queryClient) {
    queryClient.invalidateQueries(queryKeys.locations());
    this.onLookupChange(queryClient, lookupsModels.location);
  },
  onJobChange(queryClient, { isDelete, jobId }) {
    queryClient.invalidateQueries([queryKeys.jobs()[0]]);
    if (isDelete) {
      queryClient.removeQueries(queryKeys.job(jobId));
    } else {
      queryClient.invalidateQueries([queryKeys.job(jobId)[0]]);
    }
  },
  onTimeOffPolicyChange(queryClient) {
    queryClient.invalidateQueries([queryKeys.userPolicy()[0]]);
    queryClient.invalidateQueries([queryKeys.policy()[0]]);
    queryClient.invalidateQueries([queryKeys.timeOffPolicies()[0]]);
  },
  onChangeEmployee(queryClient, userId, isMyProfile) {
    queryClient.invalidateQueries(queryKeys.employee(userId));
    queryClient.invalidateQueries([queryKeys.employeeProfile()[0]]);
    queryClient.invalidateQueries([queryKeys.employees(true)[0]]);
    if (isMyProfile) {
      queryClient.invalidateQueries(queryKeys.user());
    }
    this.onChangeEmployeeStatus(queryClient);
  },

  onChangeEmployeeStatus(queryClient) {
    queryClient.invalidateQueries(queryKeys.employeeStatuses());
    this.onChangeEmployeeStatusRecord(queryClient);
  },

  onChangeEmployeeStatusRecord(queryClient, { employeeId = '' } = {}) {
    if (employeeId) {
      queryClient.invalidateQueries(
        queryKeys.employeeStatusRecords(employeeId),
      );
      queryClient.invalidateQueries(queryKeys.employee(employeeId));
      queryClient.invalidateQueries([queryKeys.reasons()[0]]);
    } else {
      queryClient.invalidateQueries([queryKeys.employeeStatusRecords()[0]]);
    }
  },
  onChangeReason(queryClient, type) {
    queryClient.invalidateQueries(
      queryKeys.reasons({ filter: { byType: type } }),
    );
    queryClient.invalidateQueries([queryKeys.employeeBonusRecords()[0]]);
  },
  onChangePaySchedule(queryClient) {
    queryClient.invalidateQueries(queryKeys.paySchedules());
  },
  onChangeCompensationRecord(queryClient, employeeId, compensationReasonType) {
    queryClient.invalidateQueries(
      queryKeys.employeeCompensationRecords(employeeId),
    );
    this.onChangePaySchedule(queryClient);
    this.onChangeReason(queryClient, compensationReasonType);
  },
  onChangeBonusRecord(queryClient, employeeId, type) {
    queryClient.invalidateQueries(queryKeys.employeeBonusRecords(employeeId));
    this.onChangeReason(queryClient, type);
  },
  onSurveyComplete(queryClient, { requestId }) {
    queryClient.invalidateQueries([queryKeys.requests([])[0]]);
    queryClient.invalidateQueries(queryKeys.employeeRequest(requestId));
    queryClient.invalidateQueries(queryKeys.manageRequest(requestId));
    queryClient.invalidateQueries([queryKeys.employeeAssessmentSurveys()[0]]);
  },
  onChangeAssetCategory(queryClient) {
    queryClient.invalidateQueries(queryKeys.assetCategories());
    this.onLookupChange(queryClient, lookupsModels.assetCategory);
    queryClient.invalidateQueries([queryKeys.employeeAssets()[0]]);
  },
  onChangeEmployeeAsset(queryClient, employeeId) {
    queryClient.invalidateQueries(queryKeys.employeeAssets(employeeId));
    this.onChangeAssetCategory(queryClient);
  },
  onChangeEmployeeJobInfo(queryClient, { reportTo }) {
    if (reportTo) {
      if (_.isArray(reportTo)) {
        reportTo.forEach((id) => {
          queryClient.invalidateQueries(queryKeys.employeeSubordinates(id));
        });
      } else {
        queryClient.invalidateQueries(queryKeys.employeeSubordinates(reportTo));
      }
    }
  },
  onChangeCompanyIntegrations(queryClient, companyId = 1) {
    queryClient.invalidateQueries(
      queryKeys.companyActiveIntegrations(companyId),
    );
  },
  onChangeFiles(queryClient, { contentId, contentType }) {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) =>
        queryKey?.length > 2 &&
        queryKey[1] === contentId &&
        queryKey[2] === contentType,
    });
  },
  onSendSignatureRequest(queryClient) {
    queryClient.invalidateQueries([queryKeys.requests([])[0]]);
    queryClient.invalidateQueries([queryKeys.request()[0]]);
  },
  onSignRequestedDocument(queryClient, { contentId, contentType, requestId }) {
    this.onChangeFiles(queryClient, { contentId, contentType });
    this.onSendSignatureRequest(queryClient);
    queryClient.invalidateQueries(queryKeys.employeeRequest(requestId));
    queryClient.invalidateQueries(queryKeys.manageRequest(requestId));
  },
  onChangeEmployeesRole(queryClient) {
    queryClient.invalidateQueries(queryKeys.allRoles()[0]);
  },
  onChangeCompensationGroup(queryClient) {
    queryClient.invalidateQueries(queryKeys.compensationGroups());
  },
  onChangeCompensationBand(queryClient) {
    queryClient.invalidateQueries(queryKeys.compensationGroups());
    queryClient.invalidateQueries(queryKeys.compensationBands());
  },
  onChangeHolidays(queryClient) {
    queryClient.invalidateQueries([queryKeys.holidays()[0]]);
    queryClient.invalidateQueries([queryKeys.employeeHolidays()[0]]);
  },
  onChangeWidget(queryClient) {
    queryClient.invalidateQueries(queryKeys.widgets());
  },
  onChangeCandidateOffer(queryClient, { candidateId }) {
    queryClient.invalidateQueries(queryKeys.candidate(candidateId));
    queryClient.invalidateQueries([queryKeys.candidatesOffers()[0]]);
  },
  onChangeLinkCategory(queryClient) {
    queryClient.invalidateQueries(queryKeys.companyLinksCategories());
    this.onChangeCompanyLink(queryClient);
  },
  onChangeCompanyLink(queryClient) {
    queryClient.invalidateQueries(queryKeys.companyLinks());
    this.onLookupChange(queryClient, lookupsModels.link);
  },
  onChangeAnnounce(queryClient) {
    queryClient.invalidateQueries(queryKeys.announcements()[0]);
    queryClient.resetQueries([queryKeys.announcement()[0]]);
  },
  onChangeJobOpeningWhiteList(queryClient) {
    queryClient.invalidateQueries(queryKeys.jobOpeningWhiteList());
  },
};
