import { Button, Flex, Group, Pill, Text, rem } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import FileList from './FileList';

export default function UploadFile({
  setSelectedFile,
  maxFileSize,
  selectedFile,
  selectedFiles,
  onFileSubmit,
  accept,
  onReject,
  multiple,
  onDeleteFiles,
  preloadedFiles,
}) {
  return (
    <>
      <Dropzone
        onDrop={(files) =>
          setSelectedFile(
            multiple
              ? files.map((item) => ({
                  file: item,
                  id: _.uniqueId(),
                  objectUrl: URL.createObjectURL(item),
                }))
              : files[0],
          )
        }
        maxSize={maxFileSize * 1024 ** 2}
        onReject={onReject}
        accept={accept.split(',')}
      >
        <Group
          justify="center"
          gap="xl"
          mih={220}
          style={{ pointerEvents: 'none' }}
        >
          <Dropzone.Accept>
            <IconUpload
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-blue-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Accept>
          <Dropzone.Reject>
            <IconX
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-red-6)',
              }}
              stroke={1.5}
            />
          </Dropzone.Reject>
          <Dropzone.Idle>
            <IconPhoto
              style={{
                width: rem(52),
                height: rem(52),
                color: 'var(--mantine-color-dimmed)',
              }}
              stroke={1.5}
            />
          </Dropzone.Idle>

          <div>
            <Text size="xl" inline>
              Drag files here or click to select files
            </Text>
            <Text size="sm" c="dimmed" inline mt={7}>
              File should not exceed {maxFileSize}mb
            </Text>
          </div>
        </Group>
      </Dropzone>
      <Flex gap={10}>
        <FileList
          files={preloadedFiles}
          onDeleteFiles={(file) => onDeleteFiles(file, true)}
          isPreloaded
        />
        {multiple && !_.isEmpty(selectedFiles) && (
          <FileList files={selectedFiles} onDeleteFiles={onDeleteFiles} />
        )}
      </Flex>
      {selectedFile && !multiple && (
        <Flex
          gap="md"
          justify="center"
          align="center"
          direction="column"
          wrap="wrap"
          p="xl"
        >
          <div>
            <Pill withRemoveButton onRemove={() => setSelectedFile()}>
              {selectedFile.name}
            </Pill>
          </div>
          {onFileSubmit && <Button onClick={onFileSubmit}>Submit</Button>}
        </Flex>
      )}
    </>
  );
}

UploadFile.propTypes = {
  setSelectedFile: PropTypes.func.isRequired,
  onFileSubmit: PropTypes.func,
  onReject: PropTypes.func,
  maxFileSize: PropTypes.number,
  selectedFile: PropTypes.object,
  selectedFiles: PropTypes.array,
  preloadedFiles: PropTypes.array,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  onDeleteFiles: PropTypes.func,
};

UploadFile.defaultProps = {
  onFileSubmit: undefined,
  onDeleteFiles: undefined,
  onReject: () => {},
  maxFileSize: 20,
  selectedFile: null,
  selectedFiles: [],
  preloadedFiles: [],
  accept: '',
  multiple: false,
};
