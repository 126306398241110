import { Button, Flex, Tabs, rem } from '@mantine/core';
import {
  IconMessageCircle,
  IconPhoto,
  IconPlus,
  IconSettings,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePageTitle } from 'utils/hooks';

import { TabList, TabsCore } from 'components/Tabs';
import { PageWrapper } from '../../components/PageWrapper';
import classes from './Hiring.module.css';
import { JobOpeningsOptions } from './components';
import { Candidates, JobOpenings, TalentGroups } from './tabs';

const tabs = ['jobOpenings', 'candidates', 'talentGroups'];

const tabLabels = {
  [tabs[0]]: 'Job Openings',
  [tabs[1]]: 'Candidates',
  [tabs[2]]: 'Talent Groups',
};

export default function Hiring({ params, globalTabName }) {
  const iconStyle = { width: rem(18), height: rem(18) };
  const defaultTab = useMemo(() => tabs[0], []);
  const { tab } = params;

  const location = useLocation();
  const navigate = useNavigate();

  const onTabChange = useCallback(
    (nextTab) => {
      navigate(`/hiring/${nextTab}`, { replace: true });
    },
    [navigate],
  );

  useEffect(() => {
    if (location.pathname === '/hiring') {
      navigate(`/hiring/${defaultTab}`);
    }
  }, [location.pathname, navigate, defaultTab]);

  const navigateToAddJob = () => {
    navigate('/hiring/job/create');
  };
  const navigateToAddTalentGroup = () => {
    navigate('/hiring/talent-group/create');
  };

  const rightSectionButton = () => {
    switch (tab) {
      case 'jobOpenings':
        return (
          <Flex gap={10}>
            <JobOpeningsOptions />
            <Button
              onClick={navigateToAddJob}
              rightSection={<IconPlus size={14} />}
            >
              Add Job
            </Button>
          </Flex>
        );
      case 'talentGroups':
        return (
          <Button
            onClick={navigateToAddTalentGroup}
            rightSection={<IconPlus size={14} />}
          >
            Add Talent Group
          </Button>
        );
      default:
        break;
    }
    return null;
  };

  usePageTitle(`Hiring - ${tabLabels?.[tab] ?? ''}`, globalTabName);

  return (
    <PageWrapper title="Hiring" rightSection={rightSectionButton()}>
      <TabsCore
        defaultValue={tab || defaultTab}
        onChange={onTabChange}
        value={tab}
      >
        <TabList>
          <Tabs.Tab
            value="jobOpenings"
            leftSection={<IconPhoto style={iconStyle} />}
          >
            Job Openings
          </Tabs.Tab>
          <Tabs.Tab
            value="candidates"
            leftSection={<IconMessageCircle style={iconStyle} />}
          >
            Candidates
          </Tabs.Tab>
          <Tabs.Tab
            value="talentGroups"
            leftSection={<IconSettings style={iconStyle} />}
          >
            Talent Groups
          </Tabs.Tab>
        </TabList>
        <Tabs.Panel value="jobOpenings">
          <JobOpenings />
        </Tabs.Panel>
        <Tabs.Panel value="candidates" className={classes.tableStyles}>
          <Candidates />
        </Tabs.Panel>
        <Tabs.Panel value="talentGroups" className={classes.tableStyles}>
          <TalentGroups />
        </Tabs.Panel>
      </TabsCore>
    </PageWrapper>
  );
}

Hiring.propTypes = {
  params: PropTypes.object,
  globalTabName: PropTypes.string,
};

Hiring.defaultProps = {
  params: {},
  globalTabName: '',
};
