import { Table } from '@mantine/core';
import { IconEdit, IconTrash } from '@tabler/icons-react';
import { ContextMenu } from 'components/ContextMenu';
import { PermissionGuard } from 'components/PermissionGuard';
import { usePermissions } from 'components/PermissionGuard/hooks';
import PropTypes from 'prop-types';
import { formatDate, getUserFullName } from 'utils';
import { tabNames } from 'utils/constants';
import { useTabNavigate } from 'utils/hooks';
import styles from '../styles.module.css';

export default function TableRow({ announcement, onEdit, onDelete }) {
  const { permissions } = usePermissions();

  const navigate = useTabNavigate();

  const menuItems = [
    {
      label: 'Edit',
      icon: IconEdit,
      onClick: () =>
        onEdit({
          modalType: 'Edit',
          ...announcement,
        }),
    },
    {
      label: 'Delete',
      icon: IconTrash,
      onClick: () => onDelete(announcement),
    },
  ];

  return (
    <Table.Tr
      key={announcement?.id}
      onClick={() =>
        navigate(`/announcement/${announcement.id}`, {
          tabName: tabNames.announcements,
        })
      }
      style={{ cursor: 'pointer' }}
      classNames={{ tr: styles.row }}
    >
      <Table.Td>{announcement?.title}</Table.Td>
      <Table.Td>{getUserFullName(announcement?.author)}</Table.Td>
      <Table.Td>{getUserFullName(announcement?.publisher)}</Table.Td>
      <Table.Td>{announcement?.status_name}</Table.Td>
      <Table.Td>{formatDate(announcement?.updated_at)}</Table.Td>
      <Table.Td ta="right">
        <PermissionGuard permission={permissions.canManageEmployee}>
          <ContextMenu menuItems={menuItems} />
        </PermissionGuard>
      </Table.Td>
    </Table.Tr>
  );
}

TableRow.propTypes = {
  announcement: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
