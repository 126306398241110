/* eslint-disable react/no-danger */
import {
  Avatar,
  Divider,
  Flex,
  Input,
  Stack,
  Text,
  TypographyStylesProvider,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useLoadAnnouncement } from 'api/hooks';
import { Loader } from 'components/Loader';
import { usePermissions } from 'components/PermissionGuard/hooks';
import FileList from 'components/UploadFile/FileList';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatDate, getUserFullName } from 'utils';

export default function AnnouncementPreview({ params }) {
  const { id: announcementId } = params;

  const navigate = useNavigate();
  const { permissions, hasPermission } = usePermissions();

  const { announcement, isLoading, isError } = useLoadAnnouncement(
    {
      announcementId,
      hasManageEmployee: hasPermission(permissions.canManageEmployee),
    },
    {
      enabled: !!announcementId,
    },
  );

  return (
    <Stack gap={10} p={10}>
      <Flex
        style={{ cursor: 'pointer' }}
        align="center"
        gap={0}
        mr="auto"
        onClick={() => navigate(-1)}
      >
        <IconArrowLeft size={15} /> Back
      </Flex>

      {isError && (
        <Input.Error>
          An error occurred while loading or you do not have access
        </Input.Error>
      )}
      {isLoading && <Loader isLoading />}

      {!isLoading && !_.isEmpty(announcement) && !isError && (
        <>
          <Text fw={500} fz={30}>
            {announcement?.title}
          </Text>

          <Divider />

          <TypographyStylesProvider>
            <div dangerouslySetInnerHTML={{ __html: announcement?.body }} />
          </TypographyStylesProvider>

          <FileList files={announcement?.files} isPreloaded />

          <Divider />

          {announcement?.publisher && (
            <Flex align="center" gap={10}>
              <Avatar src={announcement?.publisher?.avatar_filename} />
              <Stack gap={0}>
                <Text fw={500}>{getUserFullName(announcement?.publisher)}</Text>
                <Text fz={15}>{formatDate(announcement?.published_at)}</Text>
              </Stack>
            </Flex>
          )}
        </>
      )}
    </Stack>
  );
}

AnnouncementPreview.propTypes = {
  params: PropTypes.object,
};

AnnouncementPreview.defaultProps = {
  params: {},
};
