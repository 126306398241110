import _ from 'lodash';

export const employeeTypes = {
  all: '0',
  someEmployee: '1',
};

// todo refactor new hook
export const filterOnlySelectedValues = (filters) => {
  const result = {};
  _.keys(filters).forEach((model) => {
    const arr = filters[model]
      ?.filter((val) => val?.value)
      ?.map(({ name, id }) => ({
        name,
        id,
      }));
    if (!_.isEmpty(arr)) result[model] = arr;
  });

  return result;
};

// todo refactor new hook
export const markEmployeeFiltersAsSelected = (filters) => {
  const result = {};
  _.keys(filters).forEach((model) => {
    result[model] = filters[model]?.map(({ value, id, ...rest }) => ({
      ...rest,
      id: +id,
      value: true,
    }));
  });

  return result;
};
