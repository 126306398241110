/* eslint-disable react/jsx-props-no-spreading */
import { Flex, Loader as Lr } from '@mantine/core';
import PropTypes from 'prop-types';

export default function Loader({ isLoading, ...props }) {
  return <Flex justify="center">{isLoading && <Lr {...props} />}</Flex>;
}

Loader.propTypes = {
  isLoading: PropTypes.bool,
};

Loader.defaultProps = {
  isLoading: false,
};
