import { Flex, Grid, Paper, Stack, Text, Tooltip, rem } from '@mantine/core';
import { IconFileTypePdf, IconFolder } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import { fileTypes } from 'utils/constants';
import FileContextMenu from './FileContextMenu';

export default function FileColumn({
  file,
  onClick,
  onDelete,
  onDownload,
  fileActions,
}) {
  const isFolder = file?.type === fileTypes.directory;
  return (
    <Grid.Col
      key={file.id}
      span={3}
      style={{ cursor: 'pointer' }}
      onClick={() => onClick?.(file)}
      position="absolute"
    >
      <Flex justify="flex-end">
        <FileContextMenu
          file={file}
          onDelete={onDelete}
          onDownload={onDownload}
          position="absolute"
          fileActions={fileActions}
        />
      </Flex>
      <Tooltip label={file.name} position="bottom" openDelay={500}>
        <Paper shadow="sm" radius="md" p="xl">
          <Flex
            mih={50}
            gap="md"
            justify="center"
            align="center"
            direction="column"
            wrap="wrap"
            w="100%"
          >
            {isFolder && (
              <Stack gap={10} align="center" w="100%" ta="center">
                <IconFolder style={{ width: rem(50), height: rem(50) }} />
                <Text style={{ wordBreak: 'break-all' }} lineClamp={2} w="100%">
                  {file.name}
                </Text>
                <Text size="sm" c="dimmed">
                  {file.metadata?.children ?? 0} Items
                </Text>
              </Stack>
            )}
            {!isFolder && (
              <Stack gap={10} align="center" w="100%">
                <IconFileTypePdf style={{ width: rem(50), height: rem(50) }} />
                <Text style={{ wordBreak: 'break-all' }} lineClamp={2} w="100%">
                  {file.name}
                </Text>
              </Stack>
            )}
          </Flex>
        </Paper>
      </Tooltip>
    </Grid.Col>
  );
}

FileColumn.propTypes = {
  file: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  fileActions: PropTypes.array,
};

FileColumn.defaultProps = {
  onClick: () => {},
  onDelete: () => {},
  onDownload: () => {},
  fileActions: [],
};
