import {
  Button,
  Container,
  Divider,
  Flex,
  Input,
  Paper,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useLoadJobOpening } from 'api/hooks';
import { CopyButton } from 'components/CopyButton';
import { Loader } from 'components/Loader';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';

export default function JobOpeningPreview() {
  const { jobId } = useParams();
  const navigate = useNavigate();

  const {
    jobOpening,
    isLoading: isJobLoading,
    isSuccess,
    isError,
  } = useLoadJobOpening(jobId);

  return (
    <Container size="1150px" h="100vh" py={100} w="100%">
      <Flex h="100%" gap={10} w="100%">
        <Paper h="100%" pt={20} style={{ overflow: 'hidden' }} flex={1}>
          <Stack
            h="100%"
            px={20}
            style={{ borderRadius: 'var(--paper-radius)' }}
            gap={20}
          >
            <Button mr="auto" onClick={() => navigate(-1)}>
              <IconArrowLeft /> Back to reports
            </Button>
            <Loader isLoading={isJobLoading} />

            {isError && (
              <Input.Error mx="auto">
                An error occurred while loading or you do not have access
              </Input.Error>
            )}
            {isSuccess && (
              <>
                <Stack gap={0}>
                  <Title fw={500}>{jobOpening?.title}</Title>
                </Stack>
                <Divider my={20} />
                <Text>{jobOpening?.description}</Text>
              </>
            )}
          </Stack>
        </Paper>
        {!isJobLoading && isSuccess && (
          <Stack>
            <Paper p={20}>
              <Stack>
                <Button w={250} fw={500} fz={20}>
                  Apply For This Job
                </Button>
                <Divider />
                <TextInput
                  label="Link to This Job"
                  value={window.location.href}
                  readonly
                  rightSection={<CopyButton value={window.location.href} />}
                />
              </Stack>
            </Paper>
            <DetailsItem label="Employment Type" value={jobOpening?.type} />
            <DetailsItem
              label="Minimum Experience"
              value={jobOpening?.experience}
            />
            <DetailsItem
              label="Compensation"
              value={jobOpening?.compensation}
            />
          </Stack>
        )}
      </Flex>
    </Container>
  );
}

function DetailsItem({ label, value }) {
  return (
    <Stack px={20} gap={0}>
      <Title fz={15} c="gray" fw={400}>
        {label}
      </Title>
      <Title fz={16} c="var(--dark-white)" fw={400}>
        {value}
      </Title>
      <Divider mt={10} />
    </Stack>
  );
}

DetailsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};
