import {
  Button,
  Container,
  Group,
  NumberInput,
  Select,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useLoadLookups } from 'api/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { formatSelectorDataObj, genericValidators } from 'utils';
import { lookupsModels } from 'utils/constants';

export default function JobForm({
  job,
  onSubmit,
  onDelete,
  isSubmitLoading,
  isDeleteLoading,
}) {
  const { lookups } = useLoadLookups({
    models: [lookupsModels.job],
  });

  const form = useForm({
    initialValues: {
      title: job?.title,
      status: _.toString(job?.status_id ?? ''),
      type: job?.type,
      experience: job?.experience,
      compensation: _.toNumber(job?.compensation) || job?.compensation,
      location: job?.location,
      description: job?.description,
    },
    validate: {
      title: (value) =>
        genericValidators.notEmpty(value, 'Title is empty') ||
        genericValidators.lessThanOrEqualTo(
          value,
          50,
          'Title needs to be less than 50 chars',
        ),
      status: (value) => genericValidators.notEmpty(value, 'Status is empty'),
      type: (value) => genericValidators.notEmpty(value, 'Type is empty'),
      experience: (value) =>
        genericValidators.notEmpty(value, 'Experience is empty'),
      compensation: (value) =>
        genericValidators.notEmpty(value, 'Compensation is empty'),
      location: (value) =>
        genericValidators.notEmpty(value, 'Location is empty'),
      description: (value) =>
        genericValidators.notEmpty(value, 'Description is empty'),
    },
  });
  const isEditAction = Boolean(job);
  return (
    <Container px={0} size="md">
      <form onSubmit={form.onSubmit(onSubmit)} noValidate>
        <TextInput
          mb="md"
          name="title"
          label="Job Title"
          placeholder="Title"
          value={form.values.title ?? ''}
          onChange={(event) =>
            form.setFieldValue('title', event.currentTarget.value)
          }
          error={form.errors.title}
        />
        <Select
          mb="md"
          name="status"
          label="Job Status"
          placeholder="Pick Status"
          data={formatSelectorDataObj(lookups[lookupsModels.job])}
          value={form.values.status}
          onChange={(value) => form.setFieldValue('status', value)}
          error={form.errors.status}
        />
        <Select
          mb="md"
          name="type"
          label="Job Type"
          placeholder="Pick Type"
          data={['Full-Time', 'Part-Time', 'Contractor', 'Intern']}
          value={form.values.type}
          onChange={(value) => form.setFieldValue('type', value)}
          error={form.errors.type}
        />
        <Select
          mb="md"
          name="experience"
          label="Job Experience"
          placeholder="Pick Experience"
          data={['Junior', 'Mid-Level', 'Senior', 'Director', 'Executive']}
          value={form.values.experience}
          onChange={(value) => form.setFieldValue('experience', value)}
          error={form.errors.experience}
        />
        <NumberInput
          mb="md"
          name="compensation"
          label="Job Compensation"
          placeholder="Job Compensation"
          value={form.values.compensation}
          onChange={(value) => form.setFieldValue('compensation', value)}
          error={form.errors.compensation}
        />
        <Select
          mb="md"
          name="location"
          label="Job Location"
          placeholder="Pick Location"
          data={['Office', 'Hybrid', 'Remote']}
          value={form.values.location}
          onChange={(value) => form.setFieldValue('location', value)}
          error={form.errors.location}
        />
        <Textarea
          mb="md"
          name="description"
          label="Job Description"
          placeholder="Add information"
          minRows={3}
          value={form.values.description}
          onChange={(event) =>
            form.setFieldValue('description', event.currentTarget.value)
          }
          error={form.errors.description}
        />

        <Group gap="xl" mt="xl" justify="center">
          {isEditAction && (
            <Button
              disabled={isDeleteLoading}
              loading={isSubmitLoading}
              type="submit"
            >
              Update
            </Button>
          )}
          {!isEditAction && (
            <Button loading={isSubmitLoading} type="submit">
              Create
            </Button>
          )}
          {isEditAction && (
            <Button
              disabled={isSubmitLoading}
              loading={isDeleteLoading}
              onClick={onDelete}
              variant="filled"
              color="red"
            >
              Delete
            </Button>
          )}
        </Group>
      </form>
    </Container>
  );
}

JobForm.propTypes = {
  job: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  isDeleteLoading: PropTypes.bool,
};
JobForm.defaultProps = {
  job: null,
  onDelete: () => {},
  isSubmitLoading: false,
  isDeleteLoading: false,
};
