import axios from 'axios';
import { useCallback, useEffect } from 'react';
import { getTokenData, getTokenLoading, storeTokenLoading } from 'storage';
import { api } from './axios';
import { mockData } from './mockData';

const defaultPayload = {
  grant_type: 'password',
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
};

export const amApi = {
  logout: () => api.get('/api/users/logOut').then(({ data }) => data),
  login: (username, password) =>
    api
      .post('/api/oauth/token', {
        username,
        password,
        ...defaultPayload,
      })
      .then(({ data }) => data),
  register: (payload) =>
    api
      .post('/api/register', { ...payload, ...defaultPayload })
      .then(({ data }) => data),

  refreshToken: (payload) =>
    api
      .post('/api/oauth/token', { ...defaultPayload, ...payload })
      .then(({ data }) => data),

  getUser: () =>
    api.get('/api/users/getCurrentUser', {}).then(({ data }) => data),
  getManageEmployees: (params) =>
    api.get('/api/settings/employees', { params }).then(({ data }) => data),
  getViewEmployee: (params) =>
    api
      .get('/api/profile/employees/getAll', { params })
      .then(({ data }) => data),
  getEmployee: (params) => {
    const { userId } = params;
    return api
      .get(`/api/settings/employees/${userId}`)
      .then(({ data }) => data);
  },
  getEmployeeProfile: (params) => {
    const { userId } = params;
    return api.get(`/api/profile/employees/${userId}`).then(({ data }) => data);
  },
  updateEmployee: (id, payload) =>
    api
      .put(`/api/settings/employees/${id}`, { ...payload })
      .then(({ data }) => data),

  createEmployee: (payload) =>
    api
      .post(`/api/settings/employees`, { ...payload })
      .then(({ data }) => data),

  deleteEmployee: (id) =>
    api.delete(`/api/settings/employees/${id}`).then(({ data }) => data),

  getJobs: (params) =>
    api.get('/api/hiring/jobs', { params }).then(({ data }) => data),

  getJob: (jobId, params) =>
    api.get(`/api/hiring/jobs/${jobId}`, { params }).then(({ data }) => data),
  addJob: (payload) =>
    api.post('/api/hiring/jobs', payload).then(({ data }) => data),

  updateJob: (id, payload) =>
    api.put(`/api/hiring/jobs/${id}`, { ...payload }).then(({ data }) => data),
  deleteJob: (id) =>
    api.delete(`/api/hiring/jobs/${id}`).then(({ data }) => data),

  getCandidates: (params) =>
    api.get('/api/hiring/candidates', { params }).then(({ data }) => data),
  getCandidate: (params) => {
    const { candidateId } = params;
    return api
      .get(`/api/hiring/candidates/${candidateId}`)
      .then(({ data }) => data);
  },
  addCandidate: (payload) =>
    api.post('/api/hiring/candidates', payload).then(({ data }) => data),
  updateCandidate: (id, payload) =>
    api
      .put(`/api/hiring/candidates/${id}`, { ...payload })
      .then(({ data }) => data),
  deleteCandidate: (id) =>
    api.delete(`/api/hiring/candidates/${id}`).then(({ data }) => data),

  addTalentGroup: (payload) =>
    api.post('/api/pools', payload).then(({ data }) => data),
  getTalentGroups: () => api.get('/api/pools', {}).then(({ data }) => data),
  getTalentGroup: (params) => {
    const { talentGroupId } = params;
    return api.get(`/api/pools/${talentGroupId}`).then(({ data }) => data);
  },
  updateTalentGroup: (id, payload) =>
    api.put(`api/pools/${id}`, { ...payload }).then(({ data }) => data),
  deleteTalentGroup: (id) =>
    api.delete(`api/pools/${id}`).then(({ data }) => data),

  addCandidateToPool: (id, payload) =>
    api.post(`/api/pools/${id}/candidates`, payload).then(({ data }) => data),
  deleteCandidateToPool: (poolId, candidateId) =>
    api
      .delete(`/api/pools/${poolId}/candidate/${candidateId}`)
      .then(({ data }) => data),

  getManageRequests: (params) =>
    api
      .get('/api/management/requests', {
        params,
      })
      .then(({ data }) => data),

  getEmployeeRequests: (params) =>
    api
      .get('/api/employee/requests', {
        params,
      })
      .then(({ data }) => data),

  getRequest: (params) => {
    const { requestId } = params;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const details = mockData.requests.find((item) => item.id === requestId);
        if (details) {
          resolve(details);
        } else {
          const error = new Error('Request not found');
          error.statusCode = 404;
          reject(error);
        }
      }, 500);
    });
  },

  getFieldsSchema: (params) =>
    new Promise((resolve) => {
      setTimeout(() => {
        const response = { data: mockData.fieldsSchema, params };
        resolve(response);
      }, 500);
    }),

  getEmployeeSchema: () =>
    api.get('/api/settings/employeeSchema', {}).then(({ data }) => data),
  getProfileEmployeeSchema: () =>
    api.get('/api/profile/employees/schema', {}).then(({ data }) => data),

  getNotes: (params) =>
    api.get('/api/notes', { params }).then(({ data }) => data),

  updateNote: (id, payload, callback) =>
    api
      .put(`api/notes/${id}`, { ...payload })
      .then(({ data }) => data)
      .finally(callback),

  createNote: (payload) =>
    api.post(`api/notes`, { ...payload }).then(({ data }) => data),

  deleteNote: (id) => api.delete(`api/notes/${id}`).then(({ data }) => data),

  getManageFiles: (params) =>
    api
      .get('/api/management/files', {
        params,
      })
      .then(({ data }) => data),

  getEmployeeFiles: (params) =>
    api
      .get('/api/profile/files', {
        params,
      })
      .then(({ data }) => data),

  createFile: (payload) =>
    api
      .post(
        `api/management/files`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  deleteFile: (id) =>
    api.delete(`api/management/files/${id}`).then(({ data }) => data),
  downloadFile: (file) =>
    api
      .get(`api/profile/files/download/${file?.id}`, { responseType: 'blob' })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });
        // eslint-disable-next-line no-undef
        const url = window.URL.createObjectURL(blob);
        // eslint-disable-next-line no-undef
        const link = document.createElement('a');
        link.href = url;
        link.download = file?.name;
        link.click();
        // eslint-disable-next-line no-undef
        window.URL.revokeObjectURL(url);
      }),

  getFileBlob: (fileId) =>
    api
      .get(`api/profile/files/download/${fileId}`, { responseType: 'blob' })
      .then((response) => response),

  getNotifications: (userId, params) =>
    api
      .get(`/api/users/getAllNotification`, { params })
      .then(({ data }) => data),

  markAsRead: ({ notificationId }) =>
    api
      .post(`api/users/markReadNotification/${notificationId}`, {
        status: 1,
      })
      .then(({ data }) => data),
  getTrainings: (params) =>
    api
      .get('/api/settings/trainings/training', {
        params,
      })
      .then(({ data }) => data),
  getTrainingCategories: (params) =>
    api
      .get('/api/settings/trainings/category', {
        params,
      })
      .then(({ data }) => data),

  createTraining: (payload) =>
    api
      .post(`/api/settings/trainings/training`, { ...payload })
      .then(({ data }) => data),
  createCategory: (payload) =>
    api
      .post(`/api/settings/trainings/category`, { ...payload })
      .then(({ data }) => data),
  deleteCategory: (id) =>
    api
      .delete(`/api/settings/trainings/category/${id}`)
      .then(({ data }) => data),
  deleteTraining: (id) =>
    api
      .delete(`/api/settings/trainings/training/${id}`)
      .then(({ data }) => data),
  updateCategory: (id, payload) =>
    api
      .put(`/api/settings/trainings/category/${id}`, { ...payload })
      .then(({ data }) => data),
  updateTraining: (id, payload) =>
    api
      .put(`/api/settings/trainings/training/${id}`, { ...payload })
      .then(({ data }) => data),

  getPolicy: (id, params) =>
    api
      .get(`/api/settings/timeoff/policy/${id}`, { params })
      .then(({ data }) => data),

  getTimeOffPolicies: ({ employeeId, canManageEmployee }, params) => {
    const url = !employeeId // eslint-disable-line
      ? '/api/settings/timeoff/policy'
      : employeeId && !canManageEmployee
      ? `/api/profile/timeoff/policy`
      : `/api/settings/employees/${employeeId}/policy`;

    return api
      .get(url, {
        params,
      })
      .then(({ data }) => data);
  },
  getManageTimeOffCategories: (params) =>
    api
      .get('/api/settings/timeoff/category', {
        params,
      })
      .then(({ data }) => data),

  getUserPolicy: () =>
    api.get('/api/profile/timeoff/policy').then(({ data }) => data),

  getEmployeePolicyList: ({ employeeId }) =>
    api
      .get(`/api/settings/employees/${employeeId}/policy`)
      .then(({ data }) => data),

  getProfileTimeOffCategories: (params) =>
    api
      .get('/api/profile/timeoff/category', {
        params,
      })
      .then(({ data }) => data),

  createEmployeeRequest: (payload) =>
    api.post('/api/employee/requests', { ...payload }).then(({ data }) => data),

  createManagementRequest: (payload) =>
    api
      .post(
        '/api/management/requests',
        { ...payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  createTimeOffPolicy: (payload) =>
    api
      .post('/api/settings/timeoff/policy', { ...payload })
      .then(({ data }) => data),
  updateTimeOffPolicy: (id, payload) =>
    api
      .put(`/api/settings/timeoff/policy/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteTimeOffPolicy: (id) =>
    api.delete(`/api/settings/timeoff/policy/${id}`).then(({ data }) => data),

  createTimeOffCategory: (payload) =>
    api
      .post('/api/settings/timeoff/category', { ...payload })
      .then(({ data }) => data),
  updateTimeOffCategory: (id, payload) =>
    api
      .put(`/api/settings/timeoff/category/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteTimeOffCategory: (id) =>
    api.delete(`/api/settings/timeoff/category/${id}`).then(({ data }) => data),

  attachTimeOffPolicy: (employeeId, policyId, payload) =>
    api
      .post(
        `api/settings/employees/${employeeId}/timeoff/attach/policy/${policyId}`,
        {
          ...payload,
        },
      )
      .then(({ data }) => data),

  detachTimeOffPolicy: (employeeId, policyId) =>
    api
      .post(
        `api/settings/employees/${employeeId}/timeoff/detach/policy/${policyId}`,
        {},
      )
      .then(({ data }) => data),

  createTimeOffRecord: (payload) =>
    api
      .post('/api/settings/timeoff/record', { ...payload })
      .then(({ data }) => data),

  deleteManageTimeOffRecords: (params) =>
    api
      .delete('/api/settings/timeoff/interval', { params })
      .then(({ data }) => data),

  deleteEmployeeTimeOffRecords: (params) =>
    api
      .delete('/api/profile/timeoff/interval', { params })
      .then(({ data }) => data),

  getManageTimeOffRecords: (params) =>
    api
      .get('/api/settings/timeoff/record', {
        params,
      })
      .then(({ data }) => data),

  getProfileTimeOffRecords: (params) =>
    api
      .get('/api/profile/timeoff/records', {
        params,
      })
      .then(({ data }) => data),

  updateTimeOffRecord: (recordId, payload) =>
    api
      .put(`/api/settings/timeoff/record/${recordId}`, { ...payload })
      .then(({ data }) => data),

  manageDeleteTimeOffRecord: (recordId) =>
    api
      .delete(`/api/settings/timeoff/record/${recordId}`)
      .then(({ data }) => data),

  employeeDeleteTimeOffRecord: (recordId) =>
    api
      .delete(`/api/profile/timeoff/record/${recordId}`)
      .then(({ data }) => data),

  getPerformanceCycles: (params) =>
    api
      .get('/api/settings/performance/cycle', {
        params,
      })
      .then(({ data }) => data),

  getPerformanceCycle: (id, params) =>
    api
      .get(`/api/settings/performance/cycle/${id}`, {
        params,
      })
      .then(({ data }) => data),

  createPerformanceCycle: (payload) =>
    api
      .post('/api/settings/performance/cycle', { ...payload })
      .then(({ data }) => data),

  deletePerformanceCycle: (cycleId) =>
    api
      .delete(`/api/settings/performance/cycle/${cycleId}`)
      .then(({ data }) => data),

  updatePerformanceCycle: (cycleId, payload) =>
    api
      .put(`/api/settings/performance/cycle/${cycleId}`, { ...payload })
      .then(({ data }) => data),

  getSurveys: (params) =>
    api
      .get('/api/settings/survey', {
        params,
      })
      .then(({ data }) => data),
  getSurvey: (id, params) =>
    api
      .get(`/api/settings/survey/${id}`, {
        params,
      })
      .then(({ data }) => data),

  getEmployeeSurvey: (id, params) =>
    api
      .get(`/api/employee/requests/survey/${id}`, {
        params,
      })
      .then(({ data }) => data),

  createSurvey: (payload) =>
    api
      .post('/api/settings/survey', {
        ...payload,
      })
      .then(({ data }) => data),

  updateSurvey: (surveyId, payload) =>
    api
      .put(`/api/settings/survey/${surveyId}`, { ...payload })
      .then(({ data }) => data),

  deleteSurvey: (cycleId) =>
    api.delete(`/api/settings/survey/${cycleId}`).then(({ data }) => data),

  getUsers: (params) =>
    api
      .get('/api/administration/users/all', { params })
      .then(({ data }) => data),

  getUserTrainings: (userId, params) =>
    api
      .get(`api/employees/${userId}/trainings`, { params })
      .then(({ data }) => data),

  getUserRecords: (userId, params) =>
    api
      .get(`api/employees/${userId}/trainings/records`, { params })
      .then(({ data }) => data),

  deleteUserRecord: (recordId) =>
    api
      .delete(`/api/settings/trainings/record/${recordId}`)
      .then(({ data }) => data),

  createUserRecord: (payload) =>
    api
      .post(
        `/api/settings/trainings/record`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  updateUserRecord: (recordId, payload) =>
    api
      .post(
        `/api/settings/trainings/record/${recordId}`,
        {
          ...payload,
          _method: 'PUT',
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  getJobTitles: () =>
    api.get('/api/settings/jobTitle').then(({ data }) => data),

  createJobTitle: (payload) =>
    api.post('/api/settings/jobTitle', { ...payload }).then(({ data }) => data),

  updateJobTitle: (id, payload) =>
    api
      .put(`/api/settings/jobTitle/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteJobTitle: (id) =>
    api.delete(`/api/settings/jobTitle/${id}`).then(({ data }) => data),

  getDepartments: () =>
    api.get('/api/settings/department').then(({ data }) => data),

  createDepartment: (payload) =>
    api
      .post('/api/settings/department', { ...payload })
      .then(({ data }) => data),

  updateDepartment: (id, payload) =>
    api
      .put(`/api/settings/department/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteDepartment: (id) =>
    api.delete(`/api/settings/department/${id}`).then(({ data }) => data),

  createDivision: (payload) =>
    api.post('/api/settings/division', { ...payload }).then(({ data }) => data),

  updateDivision: (id, payload) =>
    api
      .put(`/api/settings/division/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteDivision: (id) =>
    api.delete(`/api/settings/division/${id}`).then(({ data }) => data),

  getLocations: () =>
    api.get('/api/settings/location').then(({ data }) => data),

  createLocation: (payload) =>
    api
      .post('/api/settings/location', {
        ...payload,
      })
      .then(({ data }) => data),

  updateLocation: (id, payload) =>
    api
      .put(`/api/settings/location/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteLocation: (id) =>
    api.delete(`/api/settings/location/${id}`).then(({ data }) => data),

  getDivisions: () =>
    api.get('/api/settings/division').then(({ data }) => data),

  getBuildVersion: () => api.get('/api/utils/release').then(({ data }) => data),

  getJobInformationList: (id) =>
    api
      .get(`/api/settings/employees/${id}/jobInformation`)
      .then(({ data }) => data),

  createJobInformation: (employeeId, payload) =>
    api
      .post(`/api/settings/employees/${employeeId}/jobInformation`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteJobInformation: (employeeId, params) =>
    api
      .delete(`/api/settings/employees/${employeeId}/jobInformation`, {
        params,
      })
      .then(({ data }) => data),

  updateJobInformation: (employeeId, payload) =>
    api
      .patch(`/api/settings/employees/${employeeId}/jobInformation`, {
        ...payload,
      })
      .then(({ data }) => data),

  getTasksList: (params) =>
    api.get('/api/settings/taskList', { params }).then(({ data }) => data),

  getTasks: (params) =>
    api.get('/api/settings/task', { params }).then(({ data }) => data),

  createTaskList: (payload) =>
    api
      .post('api/settings/taskList', {
        ...payload,
      })
      .then(({ data }) => data),

  updateTaskList: (id, payload) =>
    api
      .put(`api/settings/taskList/${id}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteTaskList: (id, payload) =>
    api
      .delete(`api/settings/taskList/${id}`, {
        ...payload,
      })
      .then(({ data }) => data),

  createTask: (payload) =>
    api
      .post('api/settings/task/', {
        ...payload,
      })
      .then(({ data }) => data),

  updateTask: (id, payload) =>
    api
      .put(`api/settings/task/${id}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteTask: (id, payload) =>
    api
      .delete(`api/settings/task/${id}`, {
        ...payload,
      })
      .then(({ data }) => data),

  getEmployeeBoardingList: (id, params) =>
    api
      .get(`/api/settings/employees/${id}/getEmployeeTasks`, { params })
      .then(({ data }) => data),

  copyEmployeeTasks: (id, payload) =>
    api
      .post(`/api/settings/employees/${id}/copyTasks/`, { ...payload })
      .then(({ data }) => data),

  createEmployeeTask: (payload) =>
    api
      .post('/api/settings/employeeTask', { ...payload })
      .then(({ data }) => data),

  updateEmployeeTask: (id, payload) =>
    api
      .put(`/api/settings/employeeTask/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteEmployeeTask: (id, payload) =>
    api
      .delete(`/api/settings/employeeTask/${id}`, { ...payload })
      .then(({ data }) => data),

  markAsCompletedTask: (id, payload) =>
    api
      .post(`/api/settings/employeeTask/${id}/markTaskAsCompleted`, {
        ...payload,
      })
      .then(({ data }) => data),

  getCandidateSource: (params) =>
    api
      .get('/api/hiring/candidates/sources', { params })
      .then(({ data }) => data),

  createCandidateSource: (payload) =>
    api
      .post('/api/hiring/candidates/sources', { ...payload })
      .then(({ data }) => data),

  deleteCandidateSource: (id) =>
    api.delete(`/api/hiring/candidates/sources/${id}`).then(({ data }) => data),

  getCandidateStatuses: (params) =>
    api
      .get('/api/hiring/candidates/statuses', { params })
      .then(({ data }) => data),

  createCandidateStatus: (payload) =>
    api
      .post('/api/hiring/candidates/statuses', { ...payload })
      .then(({ data }) => data),

  updateCandidateStatus: (id, payload) =>
    api
      .patch(`/api/hiring/candidates/statuses/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteCandidateStatus: (id) =>
    api
      .delete(`/api/hiring/candidates/statuses/${id}`)
      .then(({ data }) => data),

  updateCandidateStatusOrder: (payload) =>
    api
      .post('api/hiring/candidates/statuses/updateOrder', { ...payload })
      .then(({ data }) => data),

  getEmailTemplates: (params) =>
    api.get('/api/hiring/emailTemplates', { params }).then(({ data }) => data),

  createEmailTemplate: (payload) =>
    api
      .post('/api/hiring/emailTemplates', { ...payload })
      .then(({ data }) => data),

  updateEmailTemplate: (id, payload) =>
    api
      .put(`/api/hiring/emailTemplates/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteEmailTemplate: (id) =>
    api.delete(`/api/hiring/emailTemplates/${id}`).then(({ data }) => data),

  getOfferTemplates: (params) =>
    api.get('/api/hiring/offerTemplates', { params }).then(({ data }) => data),

  getCandidatesOffers: (params) =>
    api
      .get('/api/hiring/candidates/offer', { params })
      .then(({ data }) => data),

  createOfferTemplate: (payload) =>
    api
      .post('/api/hiring/offerTemplates', { ...payload })
      .then(({ data }) => data),

  updateOfferTemplate: (id, payload) =>
    api
      .put(`/api/hiring/offerTemplates/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteOfferTemplate: (id) =>
    api.delete(`/api/hiring/offerTemplates/${id}`).then(({ data }) => data),

  getCandidateQuestions: (params) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(mockData.questions, params);
      }, 500);
    }),

  getCandidateOffer: (hash, params) =>
    api
      .get(`/api/hiring/candidates/offer/${hash}`, { params })
      .then(({ data }) => data),

  signCandidateOffer: (hash) =>
    api
      .post(`/api/hiring/candidates/offer/${hash}/sign`)
      .then(({ data }) => data),

  getTemplatesVariables: (params) =>
    api.get('/api/hiring/emailVariables', { params }).then(({ data }) => data),

  createCandidateEmail: (id, payload) =>
    api
      .post(`/api/hiring/candidates/${id}/email`, { ...payload })
      .then(({ data }) => data),

  createCandidateOffer: (id, payload) =>
    api
      .post(`/api/hiring/candidates/${id}/offer`, { ...payload })
      .then(({ data }) => data),

  cancelCandidateOffer: (id) =>
    api.delete(`/api/hiring/candidates/offer/${id}`).then(({ data }) => data),

  updateCandidateOffer: (id, payload) =>
    api
      .put(`/api/hiring/candidates/offer/${id}`, { ...payload })
      .then(({ data }) => data),

  getManageRequest: (id) =>
    api.get(`/api/management/requests/${id}`).then(({ data }) => data),

  getEmployeeRequest: (id) =>
    api.get(`/api/employee/requests/${id}`).then(({ data }) => data),

  approveRequest: (id) =>
    api.post(`/api/management/requests/approve/${id}`).then(({ data }) => data),

  denyRequest: (id) =>
    api.post(`/api/management/requests/deny/${id}`).then(({ data }) => data),

  updateRequest: (id, payload) =>
    api
      .put(`/api/employee/requests/${id}`, { ...payload })
      .then(({ data }) => data),

  deleteRequest: (id) =>
    api.delete(`/api/employee/requests/${id}`).then(({ data }) => data),

  completeSurveyRequest: (requestId, payload) =>
    api
      .post(`/api/employee/requests/${requestId}/survey/save`, { ...payload })
      .then(({ data }) => data),

  signRequest: (requestId, payload) =>
    api
      .post(
        `/api/employee/requests/${requestId}/sign`,
        { ...payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  getDashboardInfo: () => api.get('/api/dashboard').then(({ data }) => data),

  sendPasswordResetLink: (params) =>
    api.get('/api/reset-password', { params }).then(({ data }) => data),

  resetPassword: async (hash, payload) =>
    api.post(
      '/api/reset-password',
      { ...payload },
      { forwardAuthorization: `Bearer ${hash}` },
    ),

  checkIsValidPasswordResetToken: async (token) =>
    api.get('/api/reset-password/check-token', {
      forwardAuthorization: `Bearer ${token}`,
    }),

  updateCurrentUser: (payload) =>
    api
      .put('/api/users/updateCurrentUser', { ...payload })
      .then(({ data }) => data),

  getLookups: (params) =>
    api.get('/api/utils/lookups', { params }).then(({ data }) => data),

  getHeadCountReportStats: (params) =>
    api
      .get('/api/reports/headcount/stats', { params })
      .then(({ data }) => data),

  getHeadCountList: (params) =>
    api
      .get('/api/reports/headcount/employees', { params })
      .then(({ data }) => data),

  exportHeadCountList: (params) =>
    api
      .get('/api/reports/headcount/export', { params })
      .then(({ data }) => data),

  updateEmployeeAvatar: (employeeId, payload) =>
    api
      .post(
        `/api/profile/employees/${employeeId}/avatar`,
        {
          ...payload,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  deleteEmployeeAvatar: (employeeId) =>
    api
      .delete(`/api/profile/employees/${employeeId}/avatar`)
      .then(({ data }) => data),

  getEmployeeStatuses: () =>
    api.get('/api/settings/status/statuses').then(({ data }) => data),

  getEmployeeStatusRecords: (employeeId) =>
    api
      .get(`/api/settings/employees/${employeeId}/statuses`)
      .then(({ data }) => data),

  createEmployeeStatus: async (payload) =>
    api.post('/api/settings/status/statuses', { ...payload }),

  deleteEmployeeStatus: (statusId) =>
    api
      .delete(`/api/settings/status/statuses/${statusId}`)
      .then(({ data }) => data),

  updateEmployeeStatus: (statusId, payload) =>
    api
      .put(`/api/settings/status/statuses/${statusId}`, { ...payload })
      .then(({ data }) => data),

  updateEmployeeStatusRecord: (recordId, payload) =>
    api
      .put(`/api/settings/status/records/${recordId}`, { ...payload })
      .then(({ data }) => data),

  createEmployeeStatusRecord: (payload) =>
    api
      .post('/api/settings/status/records', { ...payload })
      .then(({ data }) => data),

  deleteEmployeeStatusRecord: (recordId) =>
    api
      .delete(`/api/settings/status/records/${recordId}`)
      .then(({ data }) => data),

  updateEmployeeLanguage: (employeeId, payload) =>
    api
      .post(`/api/profile/employees/${employeeId}/language`, { ...payload })
      .then(({ data }) => data),

  getReasons: (params) =>
    api.get('/api/settings/reason', { params }).then(({ data }) => data),

  createReason: (payload) =>
    api.post('/api/settings/reason', { ...payload }).then(({ data }) => data),

  deleteReason: (reasonId) =>
    api.delete(`/api/settings/reason/${reasonId}`).then(({ data }) => data),

  updateReason: (reasonId, payload) =>
    api
      .put(`/api/settings/reason/${reasonId}`, { ...payload })
      .then(({ data }) => data),

  getPaySchedules: () =>
    api.get('/api/settings/compensation/schedules').then(({ data }) => data),

  createPaySchedule: (payload) =>
    api
      .post('/api/settings/compensation/schedules', { ...payload })
      .then(({ data }) => data),

  updatePaySchedule: (payScheduleId, payload) =>
    api
      .put(`/api/settings/compensation/schedules/${payScheduleId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deletePaySchedule: (payScheduleId) =>
    api
      .delete(`/api/settings/compensation/schedules/${payScheduleId}`)
      .then(({ data }) => data),

  getEmployeeCompensationRecords: (employeeId) =>
    api
      .get(`/api/settings/employees/${employeeId}/compensations`)
      .then(({ data }) => data),

  createEmployeeCompensation: (payload) =>
    api
      .post('/api/settings/compensation/compensations', { ...payload })
      .then(({ data }) => data),

  updateEmployeeCompensation: (compensationId, payload) =>
    api
      .put(`/api/settings/compensation/compensations/${compensationId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteEmployeeCompensation: (compensationId) =>
    api
      .delete(`/api/settings/compensation/compensations/${compensationId}`)
      .then(({ data }) => data),

  getEmployeeOrgTree: () =>
    api.get('/api/reports/tree').then(({ data }) => data),

  getEmployeeBonusRecords: (employeeId) =>
    api
      .get(`api/settings/employees/${employeeId}/bonuses`)
      .then(({ data }) => data),

  createEmployeeBonusRecord: (payload) =>
    api.post('/api/settings/bonus', { ...payload }).then(({ data }) => data),

  updateEmployeeBonusRecord: (bonusRecordId, payload) =>
    api
      .put(`/api/settings/bonus/${bonusRecordId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteEmployeeBonusRecord: (bonusRecordId) =>
    api.delete(`/api/settings/bonus/${bonusRecordId}`).then(({ data }) => data),

  getEmployeeAssessmentSurveys: (employeeId, params) =>
    api
      .get(`/api/settings/employees/${employeeId}/surveys/responses`, {
        params,
      })
      .then(({ data }) => data),

  getTotalEmployeesByJobInfoFilter: (params) =>
    api
      .get('/api/settings/employees/filters/employeeCount', { params })
      .then(({ data }) => data),

  getAssetCategories: () =>
    api.get('/api/settings/assetCategory').then(({ data }) => data),

  createAssetCategory: (payload) =>
    api
      .post('/api/settings/assetCategory', { ...payload })
      .then(({ data }) => data),

  updateAssetCategory: (assetCategoryId, payload) =>
    api
      .put(`/api/settings/assetCategory/${assetCategoryId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteAssetCategory: (assetCategoryId) =>
    api
      .delete(`/api/settings/assetCategory/${assetCategoryId}`)
      .then(({ data }) => data),

  getEmployeeAssets: (employeeId) =>
    api
      .get(`/api/settings/employees/${employeeId}/assets`)
      .then(({ data }) => data),

  createEmployeeAsset: (payload) =>
    api.post('/api/settings/asset', { ...payload }).then(({ data }) => data),

  updateEmployeeAsset: (assetId, payload) =>
    api
      .put(`/api/settings/asset/${assetId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteEmployeeAsset: (assetCategoryId) =>
    api
      .delete(`/api/settings/asset/${assetCategoryId}`)
      .then(({ data }) => data),

  getEmployeeSubordinates: (employeeId) =>
    api
      .get(`/api/settings/employees/${employeeId}/subordinates`)
      .then(({ data }) => data),

  createPeerFeedback: (requestId, payload) =>
    api
      .post(`/api/management/requests/dispatchPeerFeedback/${requestId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  oauthLogin: (provider, payload) =>
    api
      .get(`/api/auth/${provider}/callback`, {
        params: payload,
      })
      .then(({ data }) => data),

  formatGoogleCodeToToken: (code) =>
    axios.post('https://oauth2.googleapis.com/token', {
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      redirect_uri: `${window.location.protocol}//${window.location.host}`,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      code,
      grant_type: 'authorization_code',
    }),

  loadGoogleUserData: (accessToken) =>
    axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: { Authorization: `Bearer ${accessToken}` },
    }),

  checkIsValidUserProviderToken: (provider) =>
    api.get(`/api/users/${provider}/checkToken`).then(({ data }) => data),

  getCompanyActiveIntegrations: (companyId) =>
    api.get(`/api/company/${companyId}/integrations`).then(({ data }) => data),

  getIntegrations: () =>
    api.get(`/api/settings/company/integrations`).then(({ data }) => data),

  enableCompanyIntegration: (integrationId) =>
    api
      .post(`/api/settings/company/integrations/${integrationId}/attach`)
      .then(({ data }) => data),

  disableCompanyIntegration: (integrationId) =>
    api
      .post(`/api/settings/company/integrations/${integrationId}/detach`)
      .then(({ data }) => data),

  updateFileStatus: (fileId, payload) =>
    api
      .post(`/api/management/updateFileStatus/${fileId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  getRoles: (params) =>
    api.get('/api/settings/roles', { params }).then(({ data }) => data),

  addRolesToEmployee: (payload) =>
    api
      .post('/api/settings/roles/attach/employees', { ...payload })
      .then(({ data }) => data),

  removeEmployeeRole: (payload) =>
    api
      .post('/api/settings/roles/detach/employees', { ...payload })
      .then(({ data }) => data),

  createCompensationGroup: (payload) =>
    api
      .post('/api/settings/compensation/bands/group', { ...payload })
      .then(({ data }) => data),

  getCompensationGroups: (params) =>
    api
      .get('/api/settings/compensation/bands/group', { params })
      .then(({ data }) => data),

  updateCompensationGroup: (groupId, payload) =>
    api
      .patch(`/api/settings/compensation/bands/group/${groupId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  deleteCompensationGroup: (groupId) =>
    api
      .delete(`/api/settings/compensation/bands/group/${groupId}`)
      .then(({ data }) => data),

  updateCompensationBand: (bandId, payload) =>
    api
      .patch(`/api/settings/compensation/bands/band/${bandId}`, {
        ...payload,
      })
      .then(({ data }) => data),

  createCompensationBand: (payload) =>
    api
      .post('/api/settings/compensation/bands/band', { ...payload })
      .then(({ data }) => data),

  deleteCompensationBand: (groupId) =>
    api
      .delete(`/api/settings/compensation/bands/band/${groupId}`)
      .then(({ data }) => data),

  getCalendarData: (params) =>
    api.get('/api/calendar/events', { params }).then(({ data }) => data),

  getCalendarEventTypes: (params) =>
    api.get('/api/calendar/eventTypes', { params }).then(({ data }) => data),

  getTimeOffBalance: (params) =>
    api.get('/api/profile/timeoff/policy', { params }).then(({ data }) => data),

  getHolidays: (params) =>
    api
      .get('/api/settings/holidays/holiday', { params })
      .then(({ data }) => data),

  deleteHoliday: (holidayId) =>
    api
      .delete(`/api/settings/holidays/holiday/${holidayId}`)
      .then(({ data }) => data),

  createHoliday: (payload) =>
    api
      .post('/api/settings/holidays/holiday', { ...payload })
      .then(({ data }) => data),

  updateHoliday: (holidayId, payload) =>
    api
      .patch(`/api/settings/holidays/holiday/${holidayId}`, { ...payload })
      .then(({ data }) => data),

  copyHolidays: (payload) =>
    api
      .post('/api/settings/holidays/copy', { ...payload })
      .then(({ data }) => data),

  getWidgets: (params) =>
    api
      .get('/api/dashboard/widgets/getAllUsedAllowed', { params })
      .then(({ data }) => data),

  attachWidget: (payload) =>
    api
      .post('/api/dashboard/widgets/manage', { ...payload })
      .then(({ data }) => data),

  detachWidget: (widgetId) =>
    api
      .delete(`/api/dashboard/widgets/manage/${widgetId}`)
      .then(({ data }) => data),

  updateWidgetPosition: (payload) =>
    api
      .post('/api/dashboard/widgets/updatePosition', { ...payload })
      .then(({ data }) => data),

  getEmployeeHolidays: (params) =>
    api.get('/api/profile/holidays', { params }).then(({ data }) => data),

  createLinkCategory: (payload) =>
    api
      .post('/api/settings/links/category', { ...payload })
      .then(({ data }) => data),

  getCompanyLinks: () =>
    api.get('/api/settings/links/link').then(({ data }) => data),

  createCompanyLink: (payload) =>
    api
      .post('/api/settings/links/link', { ...payload })
      .then(({ data }) => data),

  reorderCompanyLinks: (payload) =>
    api
      .post('/api/settings/links/link/updateOrder', { ...payload })
      .then(({ data }) => data),

  updateCompanyLink: (linkId, payload) =>
    api
      .put(`/api/settings/links/link/${linkId}`, { ...payload })
      .then(({ data }) => data),

  deleteCompanyLink: (linkId) =>
    api.delete(`/api/settings/links/link/${linkId}`).then(({ data }) => data),

  deleteCompanyLinkCategory: (categoryId) =>
    api
      .delete(`/api/settings/links/category/${categoryId}`)
      .then(({ data }) => data),

  updateCompanyLinkCategory: (categoryId, payload) =>
    api
      .put(`/api/settings/links/category/${categoryId}`, { ...payload })
      .then(({ data }) => data),

  getCompanyLinksCategories: () =>
    api.get('/api/settings/links/category').then(({ data }) => data),

  updateCategoriesOrder: (payload) =>
    api
      .post('/api/settings/links/category/updateOrder', { ...payload })
      .then(({ data }) => data),

  getAnnouncements: (params) =>
    api
      .get('/api/settings/announcements/announcement', { params })
      .then(({ data }) => data),

  getMyAnnouncements: (params) =>
    api
      .get('/api/profile/announcements/my', { params })
      .then(({ data }) => data),

  getAnnouncement: (announcementId) =>
    api
      .get(`/api/settings/announcements/announcement/${announcementId}`)
      .then(({ data }) => data),

  getEmployeeAnnouncement: (announcementId) =>
    api
      .get(`/api/profile/announcements/${announcementId}`)
      .then(({ data }) => data),

  createAnnounce: (payload) =>
    api
      .post(
        '/api/settings/announcements/announcement',
        { ...payload },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  deleteAnnouncement: (announcementId) =>
    api
      .delete(`/api/settings/announcements/announcement/${announcementId}`)
      .then(({ data }) => data),

  updateAnnouncement: (announcementId, payload) =>
    api
      .post(
        `/api/settings/announcements/announcement/${announcementId}`,
        {
          _method: 'PUT',
          ...payload,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then(({ data }) => data),

  getJobOpenings: (params) =>
    api.get('/api/external/jobs', { params }).then(({ data }) => data),

  getJobOpening: (jobId) =>
    api.get(`/api/external/jobs/${jobId}`).then(({ data }) => data),

  getJobOpeningWhiteList: () =>
    api.get('/api/settings/whitelist/domains').then(({ data }) => data),

  deleteJobOpeningWhitelistItem: (recordId) =>
    api
      .delete(`/api/settings/whitelist/domains/${recordId}`)
      .then(({ data }) => data),

  createJobOpeningWhiteListItem: (payload) =>
    api
      .post('/api/settings/whitelist/domains', { ...payload })
      .then(({ data }) => data),

  updateJobOpeningListItem: (recordId, payload) =>
    api
      .put(`/api/settings/whitelist/domains/${recordId}`, { ...payload })
      .then(({ data }) => data),
};

export const useUnauthorizedInterceptor = (callback, storeTokenData) => {
  const updateAccessToken = useCallback(
    async (refreshToken) => {
      storeTokenLoading(true);
      const data = await amApi.refreshToken({
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      });
      storeTokenLoading(false);
      storeTokenData(data);
    },
    [storeTokenData],
  );

  const waitUntilTokenUpdating = useCallback(
    async () =>
      new Promise((res) => {
        let ms = 0;
        const cancelId = setInterval(() => {
          if (!getTokenLoading()) {
            res();
            clearInterval(cancelId);
          }
          // prevent loop after 5 seconds
          if (ms > 5000) {
            callback();
            storeTokenLoading(false);
            res();
            clearInterval(cancelId);
          }
          ms += 200;
        }, 200);
      }),
    [callback],
  );

  useEffect(() => {
    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error?.response?.status === 401) {
          const refreshToken = getTokenData()?.refresh_token;
          if (refreshToken) {
            const originalRequest = error.config;
            if (getTokenLoading()) {
              await waitUntilTokenUpdating();
            } else {
              await updateAccessToken(refreshToken);
            }
            // call previous request
            return api(originalRequest);
          }
          callback();
        }
        return Promise.reject(error);
      },
    );
  }, [callback, updateAccessToken, waitUntilTokenUpdating]);
};
